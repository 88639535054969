import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { Menu, MenuItem } from "@mui/material"
import * as React from "react"
import { Nav, Navbar } from "react-bootstrap"
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import {
  ABOUT_URL,
  AUTH_BASE_URL,
  BUSINESS_HOME_URL,
  DEVELOPER_DOCUMENTATION_URL,
  FRONTDESK_URL,
  HOME_URL,
  ONE_CLICK_URL,
  PRICING_URL,
} from "../../../helpers"
import {
  DarkTextLogo,
  PrimaryButton,
  PrimaryOutlinedButton,
} from "../../shared"
import { theme } from "../../themes"
import { NavBox } from "./Navbar.style"

export const Navigation: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const navButton = React.useRef<any | String>(null)
  const linksContainerRef = React.useRef<any | String>(null)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const collapseNav = () => {
    navButton.current.classList.add("collapsed")
    linksContainerRef.current.classList.remove("show")
  }

  let activeClassName: any = {
    color: theme.palette.primary.main,
  }

  const primaryBtnText =
    location.pathname === BUSINESS_HOME_URL ? "Developer Login" : "sign up"

  return (
    <NavBox className="container">
      <Navbar collapseOnSelect expand="lg" className="navBarContainer">
        {/* <Container> */}
        <NavLink to={HOME_URL} className="me-0">
          <DarkTextLogo logoClass="logo" />
        </NavLink>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" ref={navButton} />
        <Navbar.Collapse id="responsive-navbar-nav" ref={linksContainerRef}>
          <Nav className="linkContainer">
            <NavLink
              className="menuLink"
              to={ABOUT_URL}
              style={({ isActive }) => (isActive ? activeClassName : undefined)}
              onClick={() => {
                collapseNav()
              }}
            >
              About
            </NavLink>
            <span className="menuLink" onClick={handleClick}>
              Our Product <ArrowDropDownIcon className="ms-1" />
            </span>

            {/* Dropdwon */}
            <Menu
              className="menu-popup"
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  navigate(ONE_CLICK_URL)
                  handleClose()
                }}
              >
                ID Manager
              </MenuItem>
              <MenuItem
                onClick={() => {
                  window.open(FRONTDESK_URL, "_blank")
                  handleClose()
                }}
              >
                FrontDesk
              </MenuItem>
              <MenuItem
                onClick={() => {
                  window.open(DEVELOPER_DOCUMENTATION_URL, "_blank")
                  handleClose()
                }}
              >
                Single Sign On (SSO)
              </MenuItem>
              <MenuItem
                onClick={() => {
                  window.open(`${AUTH_BASE_URL}?d=console&r=/`, "_blank")
                  handleClose()
                }}
              >
                Meter Lookup (Console)
              </MenuItem>
            </Menu>

            <NavLink
              className="menuLink"
              to={PRICING_URL}
              style={({ isActive }) => (isActive ? activeClassName : undefined)}
              onClick={() => {
                collapseNav()
              }}
            >
              Pricing
            </NavLink>
          </Nav>
          <Nav className="btnContainer">
            {location.pathname !== BUSINESS_HOME_URL && (
              <PrimaryOutlinedButton
                text="Login"
                click={() => {
                  window.open(`${AUTH_BASE_URL}?d=app&r=/`, "_blank")
                }}
              />
            )}
            <PrimaryButton
              text={primaryBtnText}
              click={() => {
                const param =
                  location.pathname === BUSINESS_HOME_URL
                    ? "?d=console&r=/"
                    : "/sign-up?d=app&r=/"
                window.open(`${AUTH_BASE_URL}${param}`, "_blank")
              }}
            />
          </Nav>
        </Navbar.Collapse>
        {/* </Container> */}
      </Navbar>
    </NavBox>
  )
}
