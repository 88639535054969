import { styled } from "@mui/material";

export const NavBox = styled("nav")(({ theme }) => ({
  "& .navBarContainer": {
    padding: "2em 0",
  },

  "& .navbar-toggler": {
    background: "#E2EDFE",
    border: "none",
  },

  "& .logo": {
    marginRight: "3.5rem",
  },

  "& .linkContainer": {
    display: "flex",
    gap: "3.5rem",
    marginRight: "auto",

    "@media (max-width:991.9px)": {
      gap: "1.5rem",
      margin: "1.5rem 0",
    },
  },

  "& .menuLink": {
    textDecoration: "none",
    color: "#424242",
    fontSize: "1rem",
    fontWeight: 600,
    cursor: "pointer",
    transition: "0.3s all ease",

    "&:hover": {
      color: theme.palette.primary.main,
    },
  },

  "& .btnContainer": {
    display: "flex",
    gap: "1.5rem",

    "& .MuiButton-root": {
      height: "51px !important",
    },
  },
}));
