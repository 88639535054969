import { styled } from "@mui/material";
import { Accordion } from "@mui/material";

export const FaqAccordionBox = styled(Accordion)(({ theme }) => ({
  boxShadow: "none",

  "& .MuiAccordionSummary-root": {
    padding: "0",
  },

  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: "0",
  },

  "& .MuiAccordionSummary-content": {
    padding: "1.75rem 1.5rem",
    margin: "0",

    "& .question": {
      fontWeight: 600,
      fontSize: "1.5rem",
      color: theme.palette.secondary.main,
      marginBottom: "0",
    },

    "@media (max-width:991.9px)": {
      paddingLeft: "0",

      "& .question": {
        fontSize: "1rem",
      },
    },
  },

  "& .MuiAccordionDetails-root": {
    padding: "1.875rem 3rem 2.5rem",

    "& .answer": {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "1.65",
      marginBottom: "0",
    },

    "@media (max-width:991.9px)": {
      padding: "1.25rem 1rem",
    }
  },

  "& .MuiAccordionSummary-expandIconWrapper": {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#E2EDFE",
    marginRight: "1.5rem",

    "& .MuiSvgIcon-root": {
      width: "3rem",
      height: "1.5rem",
      color: theme.palette.primary.main,
    },

    "@media (max-width:991.9px)": {
      width: "32px",
      height: "32px",
      marginRight: "0",
    },
  },
}));
