import { styled } from "@mui/material";

export const FooterBox = styled("footer")(({ theme }) => ({
  backgroundColor: "#0C0C0C",
  padding: "3.5rem 0 2.5rem",

  "& .logoCol": {
    "@media (max-width:767.9px)": {
      display: "flex",
      justifyContent: "center",
      marginBottom: "3rem",
    },
  },

  "& .socialCol": {
    display: "flex",
    justifyContent: "flex-end",

    "@media (max-width:767.9px)": {
      justifyContent: "center",
    },
  },

  "& .socialContainer": {
    color: "#C9C9C9",
    display: "flex",
    alignItems: "center",

    "@media (max-width:767.9px)": {
      display: "block",
    },

    "& .title": {
      fontWeight: 600,
      fontSize: "1.125rem",
      margin: "0px 2.69rem 0 0",

      "@media (max-width:767.9px)": {
        margin: "1rem auto 0",
        textAlign: "center",
      },
    },

    "& .socialIcons": {
      display: "flex",
      gap: "1.625rem",

      "@media (max-width:767.9px)": {
        display: "flex",
        margin: "1.2rem auto",
      },
    },
  },

  "& .divider": {
    background: "#272727",
    borderTop: "none",
    opacity: "unset",
    margin: "1.675rem 0 2rem",
    height: "1px",
  },

  "& .aboutText": {
    color: "#AEAEAE",
    fontSize: "1rem",
    textDecation: "none",
  },

  "& .footerListSection": {
    "& .list-group-item": {
      backgroundColor: "transparent",
      paddingLeft: "0",
    },

    "& .sayHelloTitle, .listTitle": {
      color: theme.palette.primary.main,
      fontWeight: 600,
      fontSize: "1.125rem",
    },

    "& .sayHelloTitle": {
      fontSize: "1.75rem",
    },

    "& .listDetails": {
      color: "#C9C9C9",
      fontSize: "1rem",
      textDecoration: "none",
      cursor: "pointer"
    },
  },

  "& .listHolder": {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "3rem",

    "@media (max-width:767.9px)": {
      justifyContent: "flex-start",
      marginBottom: "2.5rem",
    },
  },

  "& .copyRightContainer, .privacyList": {
    color: "#787878",
    fontWeight: 400,
  },

  "& .copyRightContainer": {
    "@media (max-width:767.9px)": {
      order: 2,
      marginTop: "1.2rem",
    },
  },

  "& .privacyList": {
    display: "flex",
    justifyContent: "flex-end",

    "@media (max-width:767.9px)": {
      justifyContent: "flex-start",
      order: 1,
    },

    "& ul": {
      paddingLeft: 0,
      display: "flex",
      gap: "2.5rem",
      listStyle: "none",
      marginBottom: "0",
      cursor: "pointer"
    },
  },
}));
