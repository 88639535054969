import { featuresList } from "./features.data"
import { FeaturesWrap } from "./features.styles"
import React from "react"
const Fade = require("react-reveal/Fade")

export const Features = () => {
  return (
    <FeaturesWrap>
      <div className="container">
        {featuresList.map(fl => (
          <div className="row item-content">
            <Fade bottom>
              <div className="image">
                <Fade>
                  <img
                    loading="lazy"
                    src={fl.image}
                    alt={`feature: ${fl.title}`}
                  />
                </Fade>
              </div>
              <div className="text-content">
                <h3 className="title">{fl.title}</h3>
                <p className="detail">{fl.detail}</p>
              </div>
            </Fade>
          </div>
        ))}
      </div>
    </FeaturesWrap>
  )
}
