import { Button, styled } from "@mui/material";

const similarStyle = {
  fontSize: "1rem",
  fontWeight: 600,
  height: "49px",
  padding: "1rem 1.93rem",
  borderRadius: "12px",
  transition: "0.3s all ease",
};

export const PrimaryButtonBox = styled(Button)(({ theme }) => ({
  ...similarStyle,
  color: "#ffffff",
  backgroundColor: `${theme.palette.primary.main} !important`,
  textTransform: "capitalize",
}));

export const OutlineButtonBox = styled(Button)(({ theme }) => ({
  ...similarStyle,
  color: theme.palette.primary.main,
  border: `2px solid ${theme.palette.primary.main}`,
  textTransform: "capitalize",
}));
