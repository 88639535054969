import OneIDLogoBg from "../../../../assets/images/bg/oneid_logo_bg.png";
import { styled } from "@mui/material";

export const CardBox = styled("div")(({ theme }) => ({
  marginBottom: "1.5rem",
  borderRadius: "12px",
  border: "none",

  "& .title": {
    color: theme.palette.secondary.main,
    fontWeight: 600,
    fontSize: "1.25rem",
    margin: "0.625rem 0 1rem",
  },

  "& .detail": {
    fontWeight: 400,
    fontSize: "1rem",
    color: "#424242",
    margin: "0 2rem 0 0",
  },

  "@media (max-width:767.9px)": {
    boxShadow: "0px 3px 48px rgba(66, 66, 66, 0.08)",
    padding: "3.2rem 3.375rem",

    "& .detail": {
        margin: "0",
      },
  },
}));
