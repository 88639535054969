import * as React from "react";
import "./Globe.style.css";
import GlobeImg from "../../../../assets/images/globe/Buz_OneID_Globe.svg";
import GlobeTopIcon from "../../../../assets/images/globe/top-globe-icon.svg";
import GlobeLeftMiddleIcon from "../../../../assets/images/globe/left-middle-globe-icon.svg";
import GlobeLeftBottomIcon from "../../../../assets/images/globe/left-bottom-globe-icon.svg";
import GlobeRightMiddleIcon from "../../../../assets/images/globe/right_middle_blobe-icon.svg";
import GlobeRightBottomIcon from "../../../../assets/images/globe/right-bottom-globe-icon.svg";
import GlobeBottomIcon from "../../../../assets/images/globe/bottom-globe-icon.svg";

export const GlobeAnimation = () => {
  return (
    <div className={"globeContainer"}>
      <div className="icon-container">
        <img src={GlobeTopIcon} alt="" className="top-icon" loading="lazy" />
        <img
          src={GlobeLeftMiddleIcon}
          alt=""
          className="left-middle-icon"
          loading="lazy"
        />
        <img
          src={GlobeLeftBottomIcon}
          alt=""
          className="left-bottom-icon"
          loading="lazy"
        />
        <img
          src={GlobeRightMiddleIcon}
          alt=""
          className="right-middle-icon"
          loading="lazy"
        />
        <img
          src={GlobeRightBottomIcon}
          alt=""
          className="right-bottom-icon"
          loading="lazy"
        />
        <img
          src={GlobeBottomIcon}
          alt=""
          className="bottom-icon"
          loading="lazy"
        />
      </div>

      <img
        loading="lazy"
        src={GlobeImg}
        alt="OneID business globe"
        className={"globe img-fluid"}
      />
    </div>
  );
};
