import { styled } from "@mui/material"

export const HeaderBox = styled("header")(({ theme }) => ({
  paddingTop: "3.5rem",

  "& .heroText": {
    "&  .title": {
      fontSize: "4rem",
      fontWeight: 800,
      color: theme.palette.secondary.main,
      textTransform: "capitalize",
      textAlign: "center",
    },

    "& .details": {
      fontSize: "1.25rem",
      color: "#272727",
      margin: "1rem auto",
      textAlign: "center",
    },

    "& .MuiButton-root": {
      height: "64px ",
    },

    "@media (max-width:1199.9px)": {
      width: "100%",
      marginRight: "0",

      "&  .title": {
        fontSize: "3.25rem",
      },

      "& .details": {
        fontSize: "1.125rem",
        marginRight: "0",
      },
    },

    "@media (max-width:991.9px)": {
      marginRight: "0",

      "&  .title": {
        fontSize: "3rem",
      },

      "& .details": {
        fontSize: "1rem",
        margin: "1rem auto",
      },
    },
  },

  "& .appBtnContainer": {
    marginTop: "4rem",
  },

  "& .image-wrap": {
    marginTop: "3rem",
  },
}))
