import * as React from "react";
import iPhoneWithText from "../../../../assets/images/iphone_12_pro_max_graphite.png";
import { SectionBox } from "./PhoneTextSection.style";
import { PrimaryButton } from "../../../../components/shared";
import { useNavigate } from "react-router-dom";
const Fade = require("react-reveal/Fade");

interface IProps {
  phoneImage: string;
  title: string;
  subTitle: string;
  detail: string;
  imageOrder?: string;
  textOrder?: string;
  imageOrderLg?: string;
  textOrderLg?: string;
  buttonText: string;
  buttonLink: string;
  isExternalLink?: boolean;
}

export const PhoneTextSection: React.FC<IProps> = ({
  phoneImage,
  title,
  subTitle,
  detail,
  imageOrder = 1,
  textOrder = 2,
  imageOrderLg,
  textOrderLg,
  buttonText,
  buttonLink,
  isExternalLink = true,
}) => {
  const navigate = useNavigate();

  return (
    <SectionBox className="container">
      <div className="row">
        <div
          className={`col-lg-6 imageSection order-${imageOrder} order-lg-${imageOrderLg}`}
        >
          <div className="imageHolder">
            <img src={phoneImage} alt={title} loading="lazy" />
          </div>
        </div>
        <div
          className={`col-lg-6 textSection order-${textOrder} order-lg-${textOrderLg}`}
        >
          <div className="textHolder">
            <Fade bottom>
              <h6 className="subTitle">{subTitle}</h6>
              <h3 className="title">{title}</h3>
              <p className="detail">{detail}</p>
              <PrimaryButton
                text={buttonText}
                click={() => {
                  isExternalLink
                    ? window.open(buttonLink, "_blank")
                    : navigate(buttonLink);
                }}
              />
            </Fade>
          </div>
        </div>
      </div>
    </SectionBox>
  );
};
