import { styled } from "@mui/material";

export const TeamCardBox = styled("div")(({ theme }) => ({
  border: "none",
  marginBottom: "4rem",

  "@media (max-width:566.9px)": {
    marginBottom: "2rem",
  },

  "& .imageHolder": {
    width: "300px",
    height: "300px",
    borderRadius: "50%",
    border: "4px solid #0B61DD",
    padding: "1.25rem",
    display: "block",
    margin: "0 auto 1.5rem",
    position: "relative",

    "& img": {
      borderRadius: "50%",
      webkitFilter: "grayscale(100%)" /* Safari 6.0 - 9.0 */,
      filter: "grayscale(100%)",
      transition: ".3s all ease",

      "&:hover": {
        webkitFilter: "none" /* Safari 6.0 - 9.0 */,
        filter: "none",
      },
    },

    "@media (max-width:566.9px)": {
      width: "150px",
      height: "150px",
      borderWidth: "2px",
      padding: "0.5rem",
    },

    "@media (max-width:300.9px)": {
      width: "100px",
      height: "100px",
    },
  },

  "& .cardTextHolder": {
    display: "block",
    margin: "0 auto",
    textAlign: "center",

    "& .name": {
      color: "#272727",
      fontSize: "2rem",
      fontWeight: 600,
    },

    "& .title": {
      color: theme.palette.primary.main,
      fontSize: "1rem",
      textTransform: "uppercase",
      fontWeight: 600,
    },

    "@media (max-width:767.9px)": {
      marginBottom: "0",

      "& .name": {
        fontSize: "1.5rem",
      },

      "& .title": {
        fontSize: "0.95rem",
      },
    },

    "@media (max-width:566.9px)": {
      "& .name": {
        fontSize: "1.25rem",
      },

      "& .title": {
        fontSize: "0.75rem",
      },
    },

    "@media (max-width:300.9px)": {
      "& .name": {
        fontSize: "0.95rem",
      },

      "& .title": {
        fontSize: "0.65rem",
      },
    },
  },
}));
