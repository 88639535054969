import { Box, styled } from "@mui/material"
import OneIDLogo from "../../../../assets/images/bg/oneid_logo_bg.png"

export const AccurateAddressBox = styled(Box)(({ theme }) => ({
  paddingTop: "3.5rem",
  paddingBottom: "3.5rem",
  background: `url(${OneIDLogo})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top center",
  backgroundSize: "100%",
  height: "700px",
  alignItems: "center",
  display: "flex",

  "&  .title": {
    fontSize: "2rem",
    fontWeight: 600,
    color: theme.palette.secondary.main,
    textTransform: "capitalize",
    textAlign: "left",
  },

  "& .cta-wrap": {
    marginTop: "2rem",
  },

  "& .image-wrap": {
    marginTop: "3rem",
  },
}))
