import OneIDLogoBg from "../../../../assets/images/bg/oneid_logo_bg.png";
import { styled } from "@mui/material";

export const SectionBox = styled("section")(({ theme }) => ({
  paddingTop: "3.5rem",
  paddingBottom: "3.5rem",

  "& .imageSection": {
    backgroundImage: `url(${OneIDLogoBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
  },

  "& .imageHolder": {
    display: "flex",
    justifyContent: "center",

    "& img": {
      width: "70%",
    },
  },

  "& .textSection": {
    display: "flex",
    alignItems: "center",
  },

  "& .textHolder": {
    margin: "0 0 3rem 3rem",

    "& .subTitle": {
      color: "#3C8EEF",
      fontSize: "1.5rem",
      fontWeight: 600,
    },

    "& .title": {
      fontWeight: 800,
      fontSize: "3.5rem",
      color: "#001935",
      width: "79%",
    },

    "& .detail": {
      fontSize: "1.125rem",
      fontWeight: 400,
      color: "#272727",
      margin: "1rem 3.5rem 2rem 0",
    },

    "@media (max-width:1399.9px)": {
      margin: "0 0 1rem 0",

      "& .title": {
        fontSize: "3rem",
        width: "90%",
      },

      "& .detail": {
        fontSize: "1rem",
        margin: "1rem 1rem 1.5rem 0",
      },
    },

    "@media (max-width:991.9px)": {
      margin: "0 0 3rem 0",

      "& .title": {
        fontSize: "2.5rem",
        width: "95%",
        marginRight: "2.5rem",
      },

      "& .detail": {
        fontSize: "1rem",
        margin: "1rem 1.2rem 1.7rem 0",
      },
    },
  },
}));
