import { createTheme } from "@mui/material/styles";
import {
  amber,
  blue,
  blueGrey,
  cyan,
  green,
  grey,
  red,
} from "@mui/material/colors";

const primaryBrandColor = "#0B61DD";
const secondaryBrandColor = "#001935";

export let theme = createTheme({
  typography: {
    fontSize: 12,
    fontFamily: ["'Figtree'", "'Proxima Nova'", "Roboto", "sans-serif"].join(
      ","
    ),
  },

  zIndex: {
    appBar: 0,
  },

  palette: {
    // text: { primary: blueGrey[800] },
    // background: {
    //   default: "#F8F9FA",
    // },

    primary: {
      light: "#3181F5",
      main: primaryBrandColor,
      // dark: "#063576",
      contrastText: "#ffffff",
    },
    secondary: { light: "#00499B", main: secondaryBrandColor },

    danger: {
      light: red[300],
      main: red[500],
      dark: red[700],
    },
    success: {
      light: green[300],
      main: green[500],
      dark: green[700],
    },
    error: {
      light: "#FA7777",
      main: "#F71C1C",
      dark: "#d31717",
    },
    warning: {
      light: amber[300],
      main: amber[500],
      dark: amber[700],
    },
    info: {
      light: cyan[300],
      main: cyan[500],
      dark: cyan[700],
    },

    ctaPrimary: {
      light: blue[300],
      main: blue[500],
      dark: blue[700],
    },

    bgPrimary: {
      main: "#d1d5db",
    },
  },

  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          ":hover": {
            borderColor: grey[200],
          },
        },
      },
      defaultProps: {},
    },
    MuiAutocomplete: {
      // To prevent autocomplete chips from increasing field height
      defaultProps: {
        autoHighlight: true,
      },
    },
    MuiIcon: {
      defaultProps: {
        fontSize: "small",
      },
    },
    MuiGrid: {
      defaultProps: {
        spacing: 2,
      },
    },
    // https://github.com/mui-org/material-ui/issues/21142
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },

  mixins: {
    ellipsis: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    popperResponsive: {
      zIndex: 1640,
      position: "static",
      float: "none",
      width: "auto",
      marginTop: "0",
      backgroundColor: "transparent",
      border: "0",
      WebkitBoxShadow: "none",
      boxShadow: "none",
      color: "black",
    },
    dropdown: {
      borderRadius: "3px",
      border: "0",
      boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.26)",
      top: "100%",
      zIndex: 1000,
      minWidth: "160px",
      padding: "5px 0",
      margin: "2px 0 0",
      fontSize: "14px",
      textAlign: "left",
      listStyle: "none",
      backgroundColor: "#fff",
      WebkitBackgroundClip: "padding-box",
      backgroundClip: "padding-box",
    },
    dropdownItem: {
      fontSize: "13px",
      padding: "10px 20px",
      margin: "0 5px",
      borderRadius: "2px",
      WebkitTransition: "all 150ms linear",
      MozTransition: "all 150ms linear",
      OTransition: "all 150ms linear",
      MsTransition: "all 150ms linear",
      transition: "all 150ms linear",
      display: "block",
      clear: "both",
      fontWeight: 400,
      minHeight: 40,
      lineHeight: "1.42857143",
      color: "#333",
      whiteSpace: "nowrap",
      height: "unset",
      "&:hover": {
        backgroundColor: "#3F7BFF",
        color: "#FFFFFF",
        boxShadow:
          "0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)",
      },
    },
    toolbar: {
      display: "flex",
      overflow: "auto",
      flexWrap: "wrap",
      flexDirection: "row",
      padding: "12px 12px 8px 12px",
      justifyContent: "space-between",
    },
  },

  drawerWidth: 260,
  loadingDelay: "800ms",
});

// https://material-ui.com/guides/typescript/#customization-of-theme
declare module "@mui/material/styles/createTheme" {
  interface Theme {
    drawerWidth: number;
    loadingDelay: string;
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    drawerWidth: number;
    loadingDelay: string;
  }
}

// https://github.com/mui-org/material-ui/issues/11853#issuecomment-401425388
declare module "@mui/material/styles/createPalette" {
  interface Palette {
    danger: PaletteColor;
    ctaPrimary: PaletteColor;
    bgPrimary: PaletteColor;
  }

  interface PaletteOptions {
    danger: PaletteColorOptions;
    ctaPrimary: PaletteColorOptions;
    bgPrimary: PaletteColorOptions;
  }
}

declare module "@mui/material/styles/createMixins" {
  export interface Mixins {
    ellipsis: CSSProperties;
    popperResponsive: CSSProperties;
    dropdown: CSSProperties;
    dropdownItem: CSSProperties;
    toolbar: CSSProperties;
  }
}
