import * as React from "react";
import { CardBox } from "./UseCaseCard.style";

export interface ICaseCard {
  title: string;
  image: string;
  detail: string;
}

export const UseCaseCard: React.FC<ICaseCard> = ({ title, image, detail }) => {
  return (
    <CardBox className="card">
      <img
      loading="lazy" 
        src={image}
        alt={`Use cases for OneID solution includes ${title}`}
        width={"48"}
        height={"48"}
      />
      <h3 className="title">{title}</h3>
      <p className="detail">{detail}</p>
    </CardBox>
  );
};
