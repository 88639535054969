import { styled } from "@mui/material"

export const BackedByWrapper = styled("div")(({ theme }) => ({
  "& .backed-main": {
    backgroundColor: "transparent",
    padding: "2rem 0",
    flexDirection: "column",
    marginBottom: "3rem",
  },

  "& .title": {
    fontWeight: 500,
    fontSize: "2rem",
  },

  "& .companies": {
    marginTop: "2rem",
    gap: "40px",
  },

  [theme.breakpoints.down("md")]: {
    "& .backed-main": {
      backgroundColor: "transparent",
      padding: "2rem 0",
      flexDirection: "column",
      marginBottom: "3rem",
    },

    "& .title": {
      fontWeight: 500,
      fontSize: "2rem",
    },

    "& .companies": {
      marginTop: "2rem",
      gap: "40px",
      flexDirection: "column",
    },
  },
}))
