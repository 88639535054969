import { styled } from "@mui/material"

export const SetupWorkflowWrapper = styled("div")(({ theme }) => ({
  "& .setup-main": {
    backgroundColor: "#E5F1FF",
    padding: "5rem 3rem",
    margin: "4.5rem auto",
    borderRadius: "24px",
    flexDirection: "column",
    border: "1.5px solid #0a87a1",
  },

  "& .title": {
    fontWeight: 600,
    fontSize: "2.5rem",
    textTransform: "capitalize",
  },

  "& .detail": {
    fontSize: "1.125rem",
    fontWeight: 400,
    color: "#0C0C0C",
    width: "60%",
    margin: "1rem auto 3rem",
    textAlign: "center",
  },

  [theme.breakpoints.down("md")]: {
    "& .setup-main": {
      backgroundColor: "#E5F1FF",
      padding: "5rem 3rem",
      margin: "4.5rem auto",
      borderRadius: "1rem",
    },

    "& .title": {
      fontSize: "2rem",
      textAlign: "center",
    },

    "& .detail": {
      fontSize: "0.95rem",
      width: "90%",
      textAlign: "center",
    },
  },
}))
