import * as React from "react";
import { FaqBox } from "./Faq.style";
import SearchIcon from "../../assets/images/icons/Search.svg";
import { FaqAccordion } from "./components/FaqAccordion";
import { FaqList } from "../../mock-data/faqList";
import { IFaq, IFaqList } from "../../types";
import { PrimaryButton } from "../../components";

const getWindowWidth = () => {
  const { innerWidth: width } = window;
  return width;
};

const Faq = () => {
  const [windowWidth, setWindowWidth] = React.useState(getWindowWidth());
  const [currentFaq, setCurrentFaq] = React.useState<IFaqList[] | any[]>(
    FaqList[0].faqs
  );
  const [faqIndex, setFaqIndex] = React.useState<Number>(FaqList[0].id);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(getWindowWidth());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSelectFaqCategory = (faq: IFaqList[], selectedIndex: number) => {
    setCurrentFaq(faq);
    setFaqIndex(selectedIndex);
  };

  const handleSearch = (event: any) => {
    const value = event.target.value.toLowerCase();

    const filteredFaq = currentFaq.filter((faq: IFaqList) => {
      return faq.question.toLowerCase().includes(value);
    });

    setCurrentFaq(filteredFaq);
  };

  return (
    <FaqBox>
      <header className="headerSection">
        <div className="container">
          <div className="headerText">
            <h3 className="title">Frequently Asked Questions (FAQs)</h3>
            <p className="detail">Have any questions?</p>

            <div className="form-group">
              <input
                type={"text"}
                // onChange={(e) => {
                //   handleSearch(e);
                // }}
                className="form-group"
                placeholder="Search for questions..."
              />
              <img
                src={SearchIcon}
                alt="search icon"
                width={"16"}
                height={"16"}
              />
            </div>
          </div>
        </div>
      </header>

      <section className="container faqSection">
        <div className="row">
          <div className="col-lg-4 col-xl-3">
            <ul className="list-group faqCategoryGroup faqFrame">
              {FaqList.map((faq: IFaq, index: number) => {
                return (
                  <li
                    key={`${index}-${faq.category}`}
                    className={`list-group-item`}
                    onClick={() => {
                      handleSelectFaqCategory(faq.faqs, faq.id);
                    }}
                  >
                    <div
                      className={`name ${
                        faq.id === faqIndex ? "activeList" : ""
                      }`}
                    >
                      {faq.category}
                    </div>

                    {windowWidth <= 992 && faq.id === faqIndex && (
                      <div className="faqList">
                        {currentFaq.map((faqList: IFaqList, index: number) => {
                          return (
                            <FaqAccordion
                              key={`${index}-${faqList.question}`}
                              question={faqList.question}
                              answer={faqList.answer}
                              index={index}
                            />
                          );
                        })}
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="col-lg-8 col-xl-9">
            {windowWidth >= 992 && (
              <div className="faqList faqFrame">
                {currentFaq.map((faqList: IFaqList, index: number) => {
                  return (
                    <FaqAccordion
                      key={`${index}-${faqList.question}`}
                      question={faqList.question}
                      answer={faqList.answer}
                      index={index}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </section>

      <section className="container">
        <div className="contactSection">
          <div className="textHolder">
            <h3 className="title">Still have questions?</h3>
            <p className="detail">
              Not found an answer to your question? Get in touch with us, and we
              will respond to you shortly.
            </p>

            <PrimaryButton text="Contact Us" />
          </div>
        </div>
      </section>
    </FaqBox>
  );
};

export default Faq;
