import * as React from "react"
import { SetupWorkflowWrapper } from "./setup-workflow.styles"
import { PrimaryButton } from "../../../../components"
import { useNavigate } from "react-router-dom"
// Using require() because there's no TypeScrpt support
const Fade = require("react-reveal/Fade")

const getWindowWidth = () => {
  const { innerWidth: width } = window
  return width
}

export const SetupWorkflow = () => {
  const [windowWidth, setWindowWidth] = React.useState(getWindowWidth())
  const navigate = useNavigate()

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(getWindowWidth())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <SetupWorkflowWrapper>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 d-flex align-items-center justify-content-center setup-main">
            <Fade bottom>
              <h3 className="title">Set up your workflow with us</h3>
              <p className="detail">
                Join leading financial institutions and Fintech companies who
                trust our address verification system. Contact us now to learn
                more and integrate our API into your workflow.
              </p>
              <PrimaryButton
                text="Contact Us"
                click={() => navigate("/support")}
              />
            </Fade>
          </div>
        </div>
      </div>
    </SetupWorkflowWrapper>
  )
}
