import * as React from "react";
import { HeaderBox } from "./HeroSection.style";
import AppStoreImg from "../../../../assets/images/app_store_black.png";
import GooglePlayStoreImg from "../../../../assets/images/playstore_black.png";
import { PLAY_STORE_URL, APP_STORE_URL } from "../../../../helpers/route-links";
import { FingerPrintAnimation } from "../FingerPrint-Animation";
// Using require() because there's no TypeScrpt support
const Fade = require("react-reveal/Fade");

export const HeroSection = () => {
  return (
    <HeaderBox className="container">
      <div className="row">
        <div className="col-lg-6 d-flex align-items-center">
          <div className="heroText">
            <Fade>
              <h3 className="title">
                Sign up just got faster, login to any app in 1-click
              </h3>
            </Fade>
            <Fade bottom>
              <p className="details">
                Manage all your identity related data in a single app. Securely
                sign-up or login to your favourite apps in one-click with OneID.
              </p>
            </Fade>

            <div className="d-flex gap-3 appBtnContainer">
              <img
                loading="lazy"
                src={AppStoreImg}
                alt="OneD on app store"
                className="img-fluid"
                onClick={() => {
                  window.open(APP_STORE_URL, "_blank");
                }}
              />

              <img
                loading="lazy"
                src={GooglePlayStoreImg}
                alt="OneD on Google play"
                className="img-fluid"
                onClick={() => {
                  window.open(PLAY_STORE_URL, "_blank");
                }}
              />
            </div>

            {/* <PrimaryButton text="Get Started for Free" /> */}
          </div>
        </div>
        <div className="col-lg-6 d-flex justify-content-center justify-content-lg-end">
          <FingerPrintAnimation />
        </div>
      </div>
    </HeaderBox>
  );
};
