import * as React from "react";
import { ComplianceBox } from "./Compliance,style";

export const PrivacyPolicy = () => {
  return (
    <ComplianceBox className="container">
      <header className="headerSection">
        <h3 className="title">Privacy Policy</h3>
        <p className="detail">Effective Date: 05/31/2020</p>
      </header>

      <section className="sectionText">
        <h5 className="title">Introduction</h5>
        <p className="detail">
          Welcome to One Identity.
          <br />
          <br />
          One Identity (“us”, “we”, or “our”) operates https://oneidtech.com
          (hereinafter referred to as"Service").
          <br />
          <br />
          Our Privacy Policy governs your visit to https://oneidtech.com, and
          explains how we collect, safeguard and disclose information that
          results from your use of our Service.
          <br />
          <br />
          We use your data to provide and improve Service. By using Service, you
          agree to the collection and use of information in accordance with this
          policy. Unless otherwise defined in this Privacy Policy, the terms
          used in this Privacy Policy have the same meanings as in our Terms and
          Conditions.
          <br />
          <br />
          Our Terms and Conditions (“Terms”) govern all use of our Service and
          together with the Privacy Policy constitutes your agreement with us
          (“agreement”).
        </p>
      </section>

      <section className="sectionText">
        <h5 className="title">Definitions</h5>
        <p className="detail">
          <strong>SERVICE</strong> means the https://oneidtech.com website
          operated by One Identity.
          <br />
          <br />
          <strong>PERSONAL DATA</strong> means data about a living individual
          who can be identified from those data (or from those and other
          information either in our possession or likely to come into our
          possession).
          <br />
          <br />
          <strong>USAGE DATA</strong> is data collected automatically either
          generated by the use of Service or from Service infrastructure itself
          (for example, the duration of a page visit).
          <br />
          <br />
          <strong>COOKIES</strong> are small files stored on your device
          (computer or mobile device).
          <br />
          <br />
          <strong>DATA CONTROLLER</strong> means a natural or legal person who
          (either alone or jointly or in common with other persons) determines
          the purposes for which and the manner in which any personal data are,
          or are to be, processed. For the purpose of this Privacy Policy, we
          are a Data Controller of your data.
          <br />
          <br />
          <strong>DATA PROCESSORS (OR SERVICE PROVIDERS</strong>) means any
          natural or legal person who processes the data on behalf of the Data
          Controller. We may use the services of various Service Providers in
          order to process your data more effectively.
          <br />
          <br />
          <strong>DATA SUBJECT</strong> is any living individual who is the
          subject of Personal Data.
          <br />
          <br />
          <strong>THE USER</strong> is the individual using our Service. The
          User corresponds to the Data Subject, who is the subject of Personal
          Data.
        </p>
      </section>

      <section className="sectionText">
        <h5 className="title">Information Collection and Use</h5>
        <p className="detail">
          We collect several different types of information for various purposes
          to provide and improve our Service to you.
        </p>
      </section>

      <section className="sectionText">
        <h5 className="title">Types of Data Collected</h5>
        <p className="detail">
          <strong> Personal Data</strong>
          <br />
          <br />
          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you (“Personal Data”). Personally identifiable information
          may include, but is not limited to:
          <br />
          <br />
          (a) Email Address
          <br />
          <br />
          (b) First name and last name
          <br />
          <br />
          (c) Phone number
          <br />
          <br />
          (d) Address, State, Province, ZIP/Postal code, City
          <br />
          <br />
          (e) Cookies and Usage Data
          <br />
          <br />
          We may use your Personal Data to contact you with newsletters,
          marketing or promotional materials and other information that may be
          of interest to you. You may opt out of receiving any, or all, of these
          communications from us by following the unsubscribe link or by
          emailing at support@oneidtech.com.
          <br />
          <br />
          <strong> Usage Data</strong>
          <br />
          <br />
          We may also collect information that your browser sends whenever you
          visit our Service or when you access Service by or through a mobile
          device (“Usage Data”).
          <br />
          <br />
          This Usage Data may include information such as your computer's
          Internet Protocol address (e.g. IP address), browser type, browser
          version, the pages of our Service that you visit, the time and date of
          your visit, the time spent on those pages, unique device identifiers
          and other diagnostic data.
          <br />
          <br />
          When you access Service with a mobile device, this Usage Data may
          include information such as the type of mobile device you use, your
          mobile device unique ID, the IP address of your mobile device, your
          mobile operating system, the type of mobile Internet browser you use,
          unique device identifiers and other diagnostic data.
          <br />
          <br />
          <strong> Location Data</strong>
          <br />
          <br />
          We may use and store information about your location if you give us
          permission to do so (“Location Data”). We use this data to provide
          features of our Service, to improve and customize our Service.
          <br />
          <br />
          You can enable or disable location services when you use our Service
          at any time by way of your device settings.
          <br />
          <br />
          <strong> Tracking Cookies Data</strong>
          <br />
          <br />
          We use cookies and similar tracking technologies to track the activity
          on our Service and we hold certain information.
          <br />
          <br />
          Cookies are files with a small amount of data which may include an
          anonymous unique identifier. Cookies are sent to your browser from a
          website and stored on your device. Other tracking technologies are
          also used such as beacons, tags and scripts to collect and track
          information and to improve and analyze our Service.
          <br />
          <br />
          You can instruct your browser to refuse all cookies or to indicate
          when a cookie is being sent. However, if you do not accept cookies,
          you may not be able to use some portions of our Service. Examples of
          Cookies we use:
          <br />
          <br />
          (a) Session Cookies: We use Session Cookies to operate our Service.
          <br />
          <br />
          (b) Preference Cookies: We use Preference Cookies to remember your
          preferences and various settings.
          <br />
          <br />
          (c) Security Cookies: We use Security Cookies for security purposes.
          <br />
          <br />
          (d) Advertising Cookies: Advertising Cookies are used to serve you
          with advertisements that may be relevant to you and your interests.
          <br />
          <br />
          <strong> Other Data</strong>
          <br />
          <br />
          While using our Service, we may also collect the following
          information: sex, age, date of birth, place of birth, passport
          details, citizenship, registration at place of residence and actual
          address, telephone number (work, mobile), details of documents on
          education, qualification, professional training, employment
          agreements, non-disclosure agreements, information on bonuses and
          compensation, information on marital status, family members, social
          security (or other taxpayer identification) number, office location
          and other data.
        </p>
      </section>

      <section className="sectionText">
        <h5 className="title">Use of Data</h5>
        <p className="detail">
          We collect several different types of information for various purposes
          to provide and improve our Service to you.
        </p>
      </section>

      <section className="sectionText">
        <h5 className="title">Definitions</h5>
        <p className="detail">
          One Identity uses the collected data for various purposes:
          <br />
          <br />
          (a) to provide and maintain our Service;
          <br />
          <br />
          (b) to notify you about changes to our Service;
          <br />
          <br />
          (c) to allow you to participate in interactive features of our Service
          when you choose to do so;
          <br />
          <br />
          (d) to provide customer support;
          <br />
          <br />
          (e) to gather analysis or valuable information so that we can improve
          our Service;
          <br />
          <br />
          (f) to monitor the usage of our Service;
          <br />
          <br />
          (g) to detect, prevent and address technical issues;
          <br />
          <br />
          (h) to fulfill any other purpose for which you provide it;
          <br />
          <br />
          (i) to carry out our obligations and enforce our rights arising from
          any contracts entered into between you and us, including for billing
          and collection;
          <br />
          <br />
          (j) to provide you with notices about your account and/or
          subscription, including expiration and renewal notices,
          email-instructions, etc.;
          <br />
          <br />
          (k) to provide you with news, special offers and general information
          about other goods, services and events which we offer that are similar
          to those that you have already purchased or enquired about unless you
          have opted not to receive such information;
          <br />
          <br />
          (l) in any other way we may describe when you provide the information;
          <br />
          <br />
          (m) for any other purpose with your consent.
        </p>
      </section>

      <section className="sectionText">
        <h5 className="title">Retention of Data </h5>
        <p className="detail">
          We will retain your Personal Data only for as long as is necessary for
          the purposes set out in this Privacy Policy. We will retain and use
          your Personal Data to the extent necessary to comply with our legal
          obligations (for example, if we are required to retain your data to
          comply with applicable laws), resolve disputes, and enforce our legal
          agreements and policies.
          <br />
          <br />
          We will also retain Usage Data for internal analysis purposes. Usage
          Data is generally retained for a shorter period, except when this data
          is used to strengthen the security or to improve the functionality of
          our Service, or we are legally obligated to retain this data for
          longer time periods.
        </p>
      </section>

      <section className="sectionText">
        <h5 className="title">Transfer of Data</h5>
        <p className="detail">
          Your information, including Personal Data, may be transferred to – and
          maintained on – computers located outside of your state, province,
          country or other governmental jurisdiction where the data protection
          laws may differ from those of your jurisdiction.
          <br /> <br />
          If you are located outside United States and choose to provide
          information to us, please note that we transfer the data, including
          Personal Data, to United States and process it there.
          <br /> <br />
          Your consent to this Privacy Policy followed by your submission of
          such information represents your agreement to that transfer.
          <br /> <br />
          One Identity will take all the steps reasonably necessary to ensure
          that your data is treated securely and in accordance with this Privacy
          Policy and no transfer of your Personal Data will take place to an
          organisation or a country unless there are adequate controls in place
          including the security of your data and other personal information.
        </p>
      </section>

      <section className="sectionText">
        <h5 className="title">Disclosure of Data</h5>
        <p className="detail">
          We may disclose personal information that we collect, or you provide:
          <br />
          <br />
          (a) Disclosure for Law Enforcement
          <br />
          <br />
          Under certain circumstances, we may be required to disclose your
          Personal Data if required to do so by law or in response to valid
          requests by public authorities.
          <br />
          <br />
          (b) Business Transaction.
          <br />
          <br />
          If we or our subsidiaries are involved in a merger, acquisition or
          asset sale, your Personal Data may be transferred.
          <br />
          <br />
          (c) Other cases. We may disclose your information also:
          <br />
          <br />
          (i) to our subsidiaries and affiliates;
          <br />
          <br />
          (ii) to contractors, service providers, and other third parties we use
          to support our business;
          <br />
          <br />
          (iii) to fulfill the purpose for which you provide it;
          <br />
          <br />
          (iv) for any other purpose disclosed by us when you provide the
          information;
          <br />
          <br />
          (v) with your consent in any other cases;
          <br />
          <br />
          (vi) if we believe disclosure is necessary or appropriate to protect
          the rights, property, or safety of the Company, our customers, or
          others.
        </p>
      </section>

      <section className="sectionText">
        <h5 className="title">Security of Data</h5>
        <p className="detail">
          The security of your data is important to us but remember that no
          method of transmission over the Internet or method of electronic
          storage is 100% secure. While we strive to use commercially acceptable
          means to protect your Personal Data, we cannot guarantee its absolute
          security.
        </p>
      </section>

      <section className="sectionText">
        <h5 className="title">
          Your Data Protection Rights Under General Data Protection Regulation
          (GDPR)
        </h5>
        <p className="detail">
          If you are a resident of the European Union (EU) and European Economic
          Area (EEA), you have certain data protection rights, covered by GDPR.
          – See more at https://eur-lex.europa.eu/eli/reg/2016/679/oj
          <br />
          <br />
          We aim to take reasonable steps to allow you to correct, amend,
          delete, or limit the use of your Personal Data.
          <br />
          <br />
          If you wish to be informed what Personal Data we hold about you and if
          you want it to be removed from our systems, please email us at
          <strong>
            {" "}
            <a> support@oneidtech.com.</a>
          </strong>
          <br />
          <br />
          In certain circumstances, you have the following data protection
          rights:
          <br />
          <br />
          (a) the right to access, update or to delete the information we have
          on you;
          <br />
          <br />
          (b) the right of rectification. You have the right to have your
          information rectified if that information is inaccurate or incomplete;
          <br />
          <br />
          (c) the right to object. You have the right to object to our
          processing of your Personal Data;
          <br />
          <br />
          (d) the right of restriction. You have the right to request that we
          restrict the processing of your personal information;
          <br />
          <br />
          (e) the right to data portability. You have the right to be provided
          with a copy of your Personal Data in a structured, machine-readable
          and commonly used format;
          <br />
          <br />
          (f) the right to withdraw consent. You also have the right to withdraw
          your consent at any time where we rely on your consent to process your
          personal information;
          <br />
          <br />
          Please note that we may ask you to verify your identity before
          responding to such requests. Please note, we may not able to provide
          Service without some necessary data.
          <br />
          <br />
          You have the right to complain to a Data Protection Authority about
          our collection and use of your Personal Data. For more information,
          please contact your local data protection authority in the European
          Economic Area (EEA).
        </p>
      </section>

      <section className="sectionText">
        <h5 className="title">
          Your Data Protection Rights under the California Privacy Protection
          Act (CalOPPA)
        </h5>
        <p className="detail">
          CalOPPA is the first state law in the nation to require commercial
          websites and online services to post a privacy policy. The law’s reach
          stretches well beyond California to require a person or company in the
          United States (and conceivable the world) that operates websites
          collecting personally identifiable information from California
          consumers to post a conspicuous privacy policy on its website stating
          exactly the information being collected and those individuals with
          whom it is being shared, and to comply with this policy. – See more
          at:
          https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
          <br />
          <br />
          According to CalOPPA we agree to the following:
          <br />
          <br />
          (a) users can visit our site anonymously;
          <br />
          <br />
          (b) our Privacy Policy link includes the word “Privacy”, and can
          easily be found on the page specified above on the home page of our
          website;
          <br />
          <br />
          (c) users will be notified of any privacy policy changes on our
          Privacy Policy Page;
          <br />
          <br />
          (d) users are able to change their personal information by emailing us
          at support@oneidtech.com.
          <br />
          <br />
          <strong> Our Policy on “Do Not Track” Signals:</strong>
          <br />
          <br />
          We honor Do Not Track signals and do not track, plant cookies, or use
          advertising when a Do Not Track browser mechanism is in place. Do Not
          Track is a preference you can set in your web browser to inform
          websites that you do not want to be tracked.
          <br />
          <br />
          You can enable or disable Do Not Track by visiting the Preferences or
          Settings page of your web browser.
        </p>
      </section>

      <section className="sectionText">
        <h5 className="title">Service Providers</h5>
        <p className="detail">
          We may employ third party companies and individuals to facilitate our
          Service (“Service Providers”), provide Service on our behalf, perform
          Service-related services or assist us in analysing how our Service is
          used.
          <br />
          <br />
          These third parties have access to your Personal Data only to perform
          these tasks on our behalf and are obligated not to disclose or use it
          for any other purpose.
        </p>
      </section>

      <section className="sectionText">
        <h5 className="title">Analytics</h5>
        <p className="detail">
          We collect several different types of information for various purposes
          to provide and improve our Service to you.
        </p>
      </section>

      <section className="sectionText">
        <h5 className="title">Use of Data</h5>
        <p className="detail">
          We may use third-party Service Providers to monitor and analyze the
          use of our Service.
          <br />
          <br />
          <strong> Google Analytics</strong>
          <br />
          <br />
          Google Analytics is a web analytics service offered by Google that
          tracks and reports website traffic. Google uses the data collected to
          track and monitor the use of our Service. This data is shared with
          other Google services. Google may use the collected data to
          contextualise and personalise the ads of its own advertising network.
          <br />
          <br />
          For more information on the privacy practices of Google, please visit
          the Google Privacy Terms web page:
          https://policies.google.com/privacy?hl=en
          <br />
          <br />
          We also encourage you to review the Google's policy for safeguarding
          your data: https://support.google.com/analytics/answer/6004245
          <br />
          <br />
          <strong>Firebase</strong>
          <br />
          <br />
          Firebase is analytics service provided by Google Inc.
          <br />
          <br />
          You may opt-out of certain Firebase features through your mobile
          device settings, such as your device advertising settings or by
          following the instructions provided by Google in their Privacy Policy:
          https://policies.google.com/privacy?hl=en
          <br />
          <br />
          For more information on what type of information Firebase collects,
          please visit the Google Privacy Terms web page:
          https://policies.google.com/privacy?hl=en
          <br />
          <br />
          <strong> Cloudflare analytics</strong>
          <br />
          <br />
          Cloudflare analytics is a web analytics service operated by Cloudflare
          Inc. Read the Privacy Policy here:
          https://www.cloudflare.com/privacypolicy/
          <br />
          <br />
          <strong> Mixpanel</strong>
          <br />
          <br />
          Mixpanel is provided by Mixpanel Inc.
          <br />
          <br />
          You can prevent Mixpanel from using your information for analytics
          purposes by opting-out. To opt-out of Mixpanel service, please visit
          this page: https://mixpanel.com/optout/
          <br />
          <br />
          For more information on what type of information Mixpanel collects,
          please visit the Terms of Use page of Mixpanel:
          https://mixpanel.com/terms/
        </p>
      </section>

      <section className="sectionText">
        <h5 className="title">CI/CD tools</h5>
        <p className="detail">
          We may use third-party Service Providers to automate the development
          process of our Service.
          <br />
          <br />
          <strong> GitHub</strong>
          <br />
          <br />
          GitHub is provided by GitHub, Inc.
          <br />
          <br />
          GitHub is a development platform to host and review code, manage
          projects, and build software.
          <br />
          <br />
          For more information on what data GitHub collects for what purpose and
          how the protection of the data is ensured, please visit GitHub Privacy
          Policy page:
          https://help.github.com/en/articles/github-privacy-statement.
        </p>
      </section>

      <section className="sectionText">
        <h5 className="title">Behavioral Remarketing</h5>
        <p className="detail">
          One Identity uses remarketing services to advertise on third party
          websites to you after you visited our Service. We and our third-party
          vendors use cookies to inform, optimise and serve ads based on your
          past visits to our Service.
          <br />
          <br />
          <strong> Google Ads (AdWords)</strong>
          <br />
          <br />
          Google Ads (AdWords) remarketing service is provided by Google Inc.
          <br />
          <br />
          You can opt-out of Google Analytics for Display Advertising and
          customize the Google Display Network ads by visiting the Google Ads
          Settings page: http://www.google.com/settings/ads.
          <br />
          <br />
          Google also recommends installing the Google Analytics Opt-out Browser
          Add-on – https://tools.google.com/dlpage/gaoptout for your web
          browser. Google Analytics Opt-out Browser Add-on provides visitors
          with the ability to prevent their data from being collected and used
          by Google Analytics. For more information on the privacy practices of
          Google, please visit the Google Privacy Terms web page:
          https://policies.google.com/privacy?hl=en
          <br />
          <br />
          <strong> Twitter</strong>
          <br />
          <br />
          Twitter remarketing service is provided by Twitter Inc.
          <br />
          <br />
          You can opt-out from Twitter's interest-based ads by following their
          instructions: https://support.twitter.com/articles/20170405
          <br />
          <br />
          You can learn more about the privacy practices and policies of Twitter
          by visiting their Privacy Policy page: https://twitter.com/privacy
        </p>
      </section>

      <section className="sectionText">
        <h5 className="title">Payments</h5>
        <p className="detail">
          We may provide paid products and/or services within Service. In that
          case, we use third-party services for payment processing (e.g. payment
          processors).
          <br />
          <br />
          We will not store or collect your payment card details. That
          information is provided directly to our third-party payment processors
          whose use of your personal information is governed by their Privacy
          Policy. These payment processors adhere to the standards set by
          PCI-DSS as managed by the PCI Security Standards Council, which is a
          joint effort of brands like Visa, Mastercard, American Express and
          Discover. PCI-DSS requirements help ensure the secure handling of
          payment information.
          <br />
          <br />
          The payment processors we work with are:
          <br />
          <br />
          <strong> PayPal or Braintree:</strong>
          <br />
          <br />
          Their Privacy Policy can be viewed at
          https://www.paypal.com/webapps/mpp/ua/privacy-full
        </p>
      </section>

      <section className="sectionText">
        <h5 className="title">Links to Other Sites</h5>
        <p className="detail">
          Our Service may contain links to other sites that are not operated by
          us. If you click a third party link, you will be directed to that
          third party's site. We strongly advise you to review the Privacy
          Policy of every site you visit.
          <br />
          <br />
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </p>
      </section>

      <section className="sectionText">
        <h5 className="title">Children's Privacy</h5>
        <p className="detail">
          Our Services are not intended for use by children under the age of 13
          (“Children”).
          <br />
          <br />
          We do not knowingly collect personally identifiable information from
          Children under 13. If you become aware that a Child has provided us
          with Personal Data, please contact us. If we become aware that ce have
          collected Personal Data from Children without verification of parental
          consent, we take steps to remove that information from our servers.
        </p>
      </section>

      <section className="sectionText">
        <h5 className="title">Changes to This Privacy Policy</h5>
        <p className="detail">
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
          <br />
          <br />
          We will let you know via email and/or a prominent notice on our
          Service, prior to the change becoming effective and update “effective
          date” at the top of this Privacy Policy.
          <br />
          <br />
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
      </section>

      <section className="sectionText">
        <h5 className="title">Contact Us</h5>
        <p className="detail">
          If you have any questions about this Privacy Policy, please contact
          us:
          <br />
          <br />
          <strong>By Email:</strong> support@oneidtech.com
          <br />
          <br />
          <strong>By visiting this page on our website:</strong>{" "}
          https://oneidtech.com/contact-us
          <br />
          <br />
          <strong>By phone number:</strong> 08023319400
        </p>
      </section>
    </ComplianceBox>
  );
};
