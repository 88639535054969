import { styled } from "@mui/material"

export const ETFWrapper = styled("div")(({ theme }) => ({
  "& .setup-main": {
    backgroundColor: "transparent",
    padding: "5rem 3rem",
    margin: "4.5rem auto",
    borderRadius: "24px",
    flexDirection: "column",
  },

  "& .title": {
    fontWeight: 600,
    fontSize: "2.5rem",
    textTransform: "capitalize",
  },

  "& .detail": {
    fontSize: "1.125rem",
    fontWeight: 400,
    color: "#0C0C0C",
    width: "60%",
    margin: "1rem auto 3rem",
    textAlign: "center",
  },

  [theme.breakpoints.down("md")]: {
    "& .setup-main": {
      backgroundColor: "transparent",
      padding: "5rem 1rem",
      margin: "2rem auto",
    },

    "& .title": {
      fontSize: "2rem",
      textAlign: "center",
    },

    "& .detail": {
      fontSize: "0.95rem",
      width: "90%",
      textAlign: "center",
    },

    "& .btn-wrap": {
      "& button": {
        width: "max-content",
      },
    },
  },
}))
