import * as React from "react";
import { BusinessHomeBox } from "./Business-Home.style";
import { PrimaryButton } from "../../components";
import DeveloperCodeeSnippet from "../../assets/images/code_snippet.png";
import FrontDeskImage from "../../assets/images/frontdesk_img.png";
import FinancialServiceImage from "../../assets/images/financial_service_img.png";
import TravelImage from "../../assets/images/travel_img.png";
import { GlobeAnimation } from "./component/Globe-Animation/Globe";
import {
  AUTH_BASE_URL,
  DEVELOPER_DOCUMENTATION_URL,
  FRONTDESK_URL,
} from "../../helpers";
// Using require() because there's no TypeScrpt support
const Fade = require("react-reveal/Fade");
const Bounce = require("react-reveal/Bounce");

const getWindowWidth = () => {
  const { innerWidth: width } = window;
  return width;
};

const BusinessHome = () => {
  const [windowWidth, setWindowWidth] = React.useState(getWindowWidth());

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(getWindowWidth());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <BusinessHomeBox>
      <header className="container headerSection">
        <div className="row">
          <div className="col-lg-6 d-flex align-items-center">
            <div className="heroText">
              <Fade>
                <h3 className="title">
                  Sign-up doesn’t have to be long and annoying
                </h3>
              </Fade>
              <Fade bottom>
                <p className="details">
                  Your customers can simply login with OneID.
                </p>
                <PrimaryButton
                  text="Get Started for Free"
                  click={() => {
                    window.open(
                      `${AUTH_BASE_URL}/sign-up? "?d=console&r=/dashboard`,
                      "_blank"
                    );
                  }}
                />
              </Fade>
            </div>
          </div>
          <div className="col-lg-6 d-flex justify-content-center justify-content-lg-end">
            <GlobeAnimation />
          </div>
        </div>
      </header>

      <section className="developerSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 order-2 order-lg-1">
              <img
                loading="lazy"
                src={DeveloperCodeeSnippet}
                alt="APIs for DEVELOPERS"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 d-flex align-items-center order-1 order-lg-2">
              <div className="textHolder">
                <Fade bottom>
                  <h6 className="subTitle">APIs for DEVELOPERS</h6>
                  <h3 className="title">
                    With just one click, onboard your customers with our
                    well-documented API
                  </h3>

                  <PrimaryButton
                    text="Learn More"
                    click={() => {
                      window.open(DEVELOPER_DOCUMENTATION_URL, "_blank");
                    }}
                  />
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="frontdeskSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex align-items-center order-2 order-lg-1">
              <div className="textHolder">
                <Fade bottom>
                  <h6 className="subTitle">FRONTDESK</h6>
                  <h3 className="title">
                    Work smarter, more efficiently and increase productivity
                  </h3>
                  <p className="detail">
                    With Frontdesk, customers can now schedule and reschedule
                    appointments easily.
                  </p>
                  <PrimaryButton
                    text="Get Started"
                    click={() => {
                      window.open(FRONTDESK_URL, "_blank");
                    }}
                  />
                </Fade>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <img
                loading="lazy"
                src={FrontDeskImage}
                alt=" Work smarter, more efficiently and increase productivity"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="financialSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img
                loading="lazy"
                src={FinancialServiceImage}
                alt=" Access financial services faster"
                className="img-fluid"
              />
            </div>

            <div className="col-lg-6 d-flex align-items-center">
              <div className="textHolder">
                <Fade bottom>
                  <h6 className="subTitle">FINANCIAL SERVICES</h6>
                  <h3 className="title">Access financial services faster</h3>
                  <p className="detail">
                    Customers can now have seamless onboarding, access financial
                    services and complete transactions with just one click.
                  </p>
                  <PrimaryButton
                    text="Get Started"
                    click={() => {
                      window.open(
                        `${AUTH_BASE_URL}/sign-up? "?d=console&r=/dashboard`,
                        "_blank"
                      );
                    }}
                  />
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="travelSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex align-items-center order-2 order-lg-1">
              <div className="textHolder">
                <Fade bottom>
                  <h6 className="subTitle">TRAVEL</h6>
                  <h3 className="title">
                    Integrated travel schedule, automated, quick and seamless.
                  </h3>
                  <p className="detail">
                    Easier and faster check-in and reduced boarding time.
                  </p>
                  <PrimaryButton
                    text="Get Started"
                    click={() => {
                      window.open(
                        `${AUTH_BASE_URL}/sign-up? "?d=console&r=/dashboard`,
                        "_blank"
                      );
                    }}
                  />
                </Fade>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <img
                loading="lazy"
                src={TravelImage}
                alt=" Integrated travel schedule, automated, quick and seamless."
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>

      <section className={`${windowWidth >= 992 ? "container" : ""}`}>
        <div
          className={`${
            windowWidth >= 992 ? "" : "container"
          } workSmartSection`}
        >
          <div className="textHolder">
            <Bounce>
              <h3 className="title">Work smarter with OneID</h3>
            </Bounce>
            <PrimaryButton
              text="Start here for free"
              click={() => {
                window.open(`${AUTH_BASE_URL}/sign-up?d=app&r=/`, "_blank");
              }}
            />
          </div>
        </div>
      </section>
    </BusinessHomeBox>
  );
};

export default BusinessHome;
