import * as React from "react";
import { useLocation } from "react-router";

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

export const ScrollToTop: React.FC<Props> = ({ children }) => {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{children}</>;
};

