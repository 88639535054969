import * as React from "react";
import { Navigation } from "../Navbar";
import { Footer } from "../Footer";
import { Outlet } from "react-router-dom";

interface Props {
  children?: React.ReactNode;
}

export const Base: React.FC<Props> = ({ children }) => {
  return (
    <React.Fragment>
      <Navigation />

      <main>
        {children}
        <Outlet />
      </main>
      <Footer />
    </React.Fragment>
  );
};
