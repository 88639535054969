import * as React from "react"
import Concordia from "../../../../assets/images/concordia.png"
import ProvidusBank from "../../../../assets/images/providus-bank.png"
import Tekedia from "../../../../assets/images/tekedia.png"
import { BackedByWrapper } from "./backed-by.styles"
// Using require() because there's no TypeScrpt support
const Fade = require("react-reveal/Fade")

const getWindowWidth = () => {
  const { innerWidth: width } = window
  return width
}

export const BackedBy = () => {
  const [windowWidth, setWindowWidth] = React.useState(getWindowWidth())

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(getWindowWidth())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <BackedByWrapper>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 d-flex align-items-center justify-content-center backed-main">
            <Fade bottom>
              <h3 className="title">BACKED BY</h3>

              <div className="col-lg-12 d-flex align-items-center justify-content-center companies">
                <img src={ProvidusBank} alt="providus bank" />
                <img src={Concordia} alt="concordia" />
                <img src={Tekedia} alt="tekedia" />
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </BackedByWrapper>
  )
}
