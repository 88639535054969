import * as React from "react"
import { ETFWrapper } from "./experience-the-future.styles"
import { PrimaryButton, PrimaryOutlinedButton } from "../../../../components"
import { useNavigate } from "react-router-dom"
import { AUTH_BASE_URL } from "../../../../helpers"
// Using require() because there's no TypeScrpt support
const Fade = require("react-reveal/Fade")

const getWindowWidth = () => {
  const { innerWidth: width } = window
  return width
}

export const ExperienceTheFuture = () => {
  const [windowWidth, setWindowWidth] = React.useState(getWindowWidth())
  const navigate = useNavigate()

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(getWindowWidth())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <ETFWrapper>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 d-flex align-items-center justify-content-center setup-main">
            <Fade bottom>
              <h3 className="title">
                Experience the future of address verification
              </h3>
              <p className="detail">
                Enhance your customer onboarding process, mitigate fraud risks,
                and streamline operations with our reliable API. Get started
                today and unlock the power of efficient and accurate address
                verification.
              </p>

              <div className="d-flex align-items-center justify-content-center gap-4 btn-wrap">
                <PrimaryButton
                  text={"Get Started"}
                  click={() => {
                    window.open(
                      `${AUTH_BASE_URL}/sign-up?d=console&r=/`,
                      "_blank"
                    )
                  }}
                />

                <PrimaryOutlinedButton
                  text={"Book a Demo"}
                  click={() => navigate("/contact-us")}
                />
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </ETFWrapper>
  )
}
