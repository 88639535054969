import { IFaq } from "../types";

export const FaqList: IFaq[] = [
  {
    id: 1,
    category: "Getting Started on OneID",
    faqs: [
      {
        question: "What is OneID?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "Why OneID?",
        answer:
          "The problem of online fraud starts with Identity theft and impersonation and that is why OneID enables you to control the security and privacy of your data. We also believe that the process of constantly proving who you are can be unnecessarily long, a problem OneID solves with secure one-click login to third party businesses and services.",
      },
      {
        question: "How does it work?",
        answer:
          "After signing up and putting all necessary personal information, your personal details are tokenized in alphanumeric codes for secure access by third party businesses that you authorize. ",
      },
      {
        question: "Is OneID a registered company?",
        answer:
          "Yes, OneID is a privately held and registered company in Nigeria.",
      },
      {
        question: "Is adding my card details safe?",
        answer: "Yes, your card and every other details are safe with OneID.",
      },
      {
        question:
          "I am going to be putting all my information out there. Is it safe?",
        answer:
          "With OneID, you determine the level of information you would like to share with a third party. Your information is safe with OneID and encrypted so that unauthorized persons cannot access it.",
      },
      {
        question: "Can I manage access to my OneID account?",
        answer: "Can I manage access to my OneID account?",
      },
    ],
  },
  {
    id: 2,
    category: "KYC Management",
    faqs: [],
  },
  {
    id: 3,
    category: "Restricting Access",
    faqs: [],
  },
  {
    id: 4,
    category: "Privacy Settings",
    faqs: [],
  },
  {
    id: 5,
    category: "BVN & NIN Related Issues",
    faqs: [],
  },
  {
    id: 6,
    category: "Data Protection",
    faqs: [],
  },
];
