import OneIDLogoBg from "../../../../assets/images/bg/oneid_logo_bg.png"
import { styled } from "@mui/material"

export const FeaturesWrap = styled("div")(({ theme }) => ({
  background: "#001935",
  padding: "3rem 0",

  "& .item-content": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5rem 0",

    "&:nth-child(even)": {
      flexDirection: "row-reverse",
    },
  },

  "& .image": {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  "& .text-content": {
    width: "50%",

    "& .title": {
      color: "#F2BA1D",
      fontSize: "3rem",
      fontWeight: 600,
    },

    "& .detail": {
      color: "#fcfcfc",
      fontSize: "1.1rem",
    },
  },

  [theme.breakpoints.down("md")]: {
    "& .item-content": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      padding: "2rem 0.5rem",
    },

    "& .image": {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",

      "& img": {
        width: "100px",
        height: "100px",
      },
    },

    "& .text-content": {
      marginTop: "1rem",
      width: "100%",

      "& .title": {
        fontSize: "1.95rem",
      },

      "& .detail": {
        fontSize: "0.85rem",
      },
    },
  },
}))
