import { styled } from "@mui/material";

export const FaqBox = styled("div")(({ theme }) => ({
  "& .headerSection": {
    paddingTop: "2.5rem",
    paddingBottom: "8rem",
    background: theme.palette.secondary.main,

    "& .headerText": {
      textAlign: "center",

      "& .title": {
        fontSize: "1.125rem",
        fontWeight: 600,
        color: "#AEAEAE",
      },

      "& .detail": {
        fontSize: "2.5rem",
        fontWeight: 600,
        color: "#FFFFFF",
        margin: "4rem 0 2.5rem",
      },
    },

    "& .form-group": {
      position: "relative",
      width: "fit-content",
      margin: "0 auto",

      "& input": {
        width: "400px",
        height: "50px",
        borderRadius: "12px",
        padding: "1rem",
        border: "1px solid #272727",

        "& :focus": {
          boxShadow: "none",
          outline: "none",
          border: "none",
        },
      },

      "& img": {
        position: "absolute",
        top: "16.5px",
        right: "1rem",
      },
    },

    "@media (max-width:991.9px)": {
      paddingBottom: "6rem",

      "& .headerText": {
        "& .title": {
          fontSize: "0.875rem",
        },

        "& .detail": {
          fontSize: "1.125rem",
          margin: "2.5rem 0 1rem",
        },
      },

      "& .form-group": {
        "& input": {
          width: "265px",
          height: "48px",
          padding: "1rem",
        },

        "& img": {
          top: "1rem",
        },
      },
    },
  },

  "& .faqSection": {
    paddingTop: "3.5rem",
    paddingBottom: "3.5rem",

    "@media (max-width:991.9px)": {
      paddingTop: "1.5rem",
      paddingBottom: "2.5rem",
    },
  },

  "& .faqFrame": {
    padding: "1.5rem",
    borderRadius: "12px",
    border: "1px solid #E4E4E4",

    "@media (max-width:991.9px)": {
      padding: "0",
      border: "none",
    },
  },

  "& .faqList": {
    "@media (max-width:991.9px)": {
      padding: "0 1.5rem",
    },
  },

  "& .faqCategoryGroup": {
    paddingLeft: "0",

    "& .list-group-item": {
      border: "none",
      marginBottom: "1.5rem",
      cursor: "pointer",
      transition: ".3s all ease",
      padding: "0",

      "& .name": {
        fontWeight: 600,
        fontSize: "1rem",
        color: "#272727",
        padding: "1rem 1.75rem",
        borderRadius: "0",
        borderWidth: "0 0 0 4px",
        borderStyle: "solid",
        borderColor: "transparent",
      },

      "& .activeList": {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },

    "@media (max-width:991.9px)": {
      "& .list-group-item": {
        marginBottom: "2.5rem",
        border: "1px solid #E4E4E4",
        borderRadius: "12px",
        paddingTop: "1.2rem",
        paddingBottom: "1.2rem",

        "& .name": {
          fontSize: "1.5rem",
        },
      },
    },
  },

  "& .contactSection": {
    marginTop: "2.5rem",
    marginBottom: "3.5rem",
    padding: "5rem 3rem",
    background: "#E7F3FE",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& .textHolder": {
      textAlign: "center",
      width: "45%",

      "& .title": {
        fontSize: "2.5rem",
        fontWeight: 600,
      },

      "& .detail": {
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: "1.65",
        margin: "0.5rem 0 2.5rem",
      },
    },

    "@media (max-width:991.9px)": {
      padding: "3.5rem 1.5rem",

      "& .textHolder": {
        width: "100%",

        "& .title": {
          fontSize: "1.5rem",
        },

        "& .detail": {
          fontSize: "0.875rem",
        },
      },
    },
  },
}));
