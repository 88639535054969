import * as React from "react"
import { TeamCardBox } from "./TeamCard.style"
import { ITeam } from "../../../../mock-data"
const Zoom = require("react-reveal/Zoom")

export const TeamCard: React.FC<ITeam> = ({ name, title, photo }) => {
  return (
    <TeamCardBox className="card">
      <Zoom>
        <div className="imageHolder">
          <div className="overlay"></div>
          <img
            src={photo}
            alt={`${name}, ${title} of OneID`}
            className="img-fluid"
          />
        </div>
      </Zoom>
      <div className="cardTextHolder">
        <h3 className="name">{name}</h3>
        <p className="title">{title}</p>
      </div>
    </TeamCardBox>
  )
}
