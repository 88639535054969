import { styled } from "@mui/material";
import OneIDWaterMark from "../../assets/images/bg/about_pg_oneid_watermark.png";

export const AboutBox = styled("div")(({ theme }) => ({
  "& .headerSection": {
    paddingTop: "3rem",
    paddingBottom: "5rem",
    backgroundImage: `url(${OneIDWaterMark})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "50%",
    backgroundPosition: "right",

    "@media (max-width:991.9px)": {
      backgroundImage: "none",
    },

    "& .headerText": {
      width: "80%",
      margin: "1rem auto 5.5rem 0",
      textAlign: "justify",

      "& .title": {
        fontSize: "5.5rem",
        fontWeight: 800,
        marginBottom: "1.25rem",
        color: theme.palette.secondary.main,
      },

      "& .detail": {
        fontSize: "1.125rem",
        fontWeight: 400,
        lineHeight: "1.75",
      },

      "@media (max-width:991.9px)": {
        width: "100%",
        margin: "0 0 3rem 0",

        "& .title": {
          fontSize: "4rem",
        },

        "& .detail": {
          fontSize: "1rem",
        },
      },
    },
  },

  "& .textHolder": {
    width: "80%",
    margin: "1rem auto 1rem 0",
    textAlign: "justify",

    "& .title": {
      fontSize: "3.5rem",
      fontWeight: 700,
      marginBottom: "1rem",
      color: theme.palette.secondary.main,
    },

    "& .detail": {
      fontSize: "1.125rem",
      fontWeight: 400,
    },

    "@media (max-width:991.9px)": {
      width: "100%",
      margin: "2rem 0 ",

      "& .title": {
        fontSize: "2rem",
      },

      "& .detail": {
        fontSize: "1rem",
      },
    },
  },

  "& .visionSection": {
    "& img": {
      width: "50%",
      display: "block",
      margin: "0 20% 0 auto",

      "@media (max-width:991.9px)": {
        margin: "0 auto",
      },
    },
  },

  "& .coreValueSection": {
    paddingTop: "3.5rem",
    paddingBottom: "3.5rem",

    "@media (max-width:767.9px)": {
      paddingTop: "1.5rem",
      paddingBottom: "1.5rem",
    },
  },

  "& .coreCard": {
    textAlign: "center",
    padding: "2.5rem",
    minHeight: "365px",
    border: "none",

    "& .title": {
      fontSize: "1.5rem",
      fontWeight: 600,
      textTransform: "uppercase",
      color: theme.palette.secondary.main,
      margin: "2.5rem 0 0.5rem",
    },

    "& img": {
      display: "block",
      margin: "0 auto",
    },

    "& .detail": {
      color: "#272727",
      fontSize: "1.125rem",
      fontWeight: 400,
    },

    "@media (max-width:991.9px)": {
      border: "0.484694px solid #F2F2F2",
      boxShadow: "0px 7.7551px 23.2653px rgba(147, 147, 147, 0.04)",
      borderRadius: "11.6327px",
      marginBottom: "2rem",
    },
  },

  "& .teamSection": {
    "& .textHolder": {
      marginBottom: "3.5rem",

      "@media (max-width:767.9px)": {
        textAlign: "center",
      },
    },
  },
}));
