import { styled } from "@mui/material";

export const HeaderBox = styled("header")(({ theme }) => ({
  paddingTop: "3.5rem",
  paddingBottom: "3.5rem",

  "& .heroText": {
    marginRight: "1.5rem",

    "&  .title": {
      fontSize: "4rem",
      fontWeight: 800,
      color: theme.palette.secondary.main,
      textTransform: "capitalize",
    },

    "& .details": {
      fontSize: "1.25rem",
      color: "#272727",
      margin: "1rem 3rem 1.7rem 0",
    },

    "& .MuiButton-root": {
      height: "64px ",
    },

    "@media (max-width:1199.9px)": {
      width: "100%",
      marginRight: "0",

      "&  .title": {
        fontSize: "3.25rem",
      },

      "& .details": {
        fontSize: "1.125rem",
        marginRight: "0",
      },
    },

    "@media (max-width:991.9px)": {
      marginRight: "0",

      "&  .title": {
        fontSize: "3rem",
      },

      "& .details": {
        fontSize: "1rem",
        margin: "1rem 1.5rem 1.7rem 0",
      },
    },
  },

  "& .appBtnContainer": {
    "& img": {
      cursor: "pointer",
    },

    "@media (max-width:1199.9px)": {
      "& img": {
        width: "40%",
      },
    },

    "@media (max-width:991.9px)": {
      width: "60%",

      "& img": {
        width: "45%",
      },
    },

    "@media (max-width:767.9px)": {
      width: "100%",
      
      "& img": {
        width: "45%",
      },
    },
  },

  "& .fingerPrintContainer":{
    
  }
}));
