import InstantVerificationImage from "../../../../assets/images/instant-verification.svg"
import SecureImage from "../../../../assets/images/checkbox.svg"
import SeamlessImage from "../../../../assets/images/database.svg"
import ScalableImage from "../../../../assets/images/secure.svg"
import CustomizeImage from "../../../../assets/images/customize.svg"

export interface IFeature {
  title: string
  image: string
  detail: string
}

export const featuresList: IFeature[] = [
  {
    title: "Instant Verification",
    detail:
      "Eliminate delays and enhance operational efficiency with our lightning-fast verification process. Access verified addresses within seconds, empowering you to make informed decisions promptly.",
    image: InstantVerificationImage,
  },
  {
    title: "Reliable Data",
    detail:
      "Our API harnesses the power of reliable electricity meter data to validate addresses. Leverage this trusted source to ensure accuracy and authenticity in your verification process.",
    image: SecureImage,
  },
  {
    title: "Seamless API Integration",
    detail:
      "We understand the importance of seamless integration with your existing systems. Our API is designed to be easily integrated, minimizing disruption and allowing you to leverage the benefits of address verification without hassle.",
    image: SeamlessImage,
  },
  {
    title: "Scalable and Secure",
    detail:
      "Whether you're a small financial institution or a large-scale Fintech enterprise, our API solution is designed to scale with your needs. Rest assured that our system adheres to stringent security standards, safeguarding sensitive customer information.",
    image: ScalableImage,
  },
  {
    title: "Customizable Solutions",
    detail:
      "Tailor our address verification system to align with your specific requirements. We offer flexible options to accommodate your unique business needs, ensuring a seamless fit into your operations.",
    image: CustomizeImage,
  },
]
