import { Box, Typography } from "@mui/material"
import React from "react"

export const UnSecuredView: React.FC = () => {
  return (
    <Box
      sx={{
        padding: "2rem 1rem",
        textAlign: "center",
      }}
    >
      <Typography variant="h3" color="red">
        If you see this page, The Web App link you have clicked on is under
        ClickJacking security attack.
      </Typography>
      <Typography variant="h5" sx={{ paddingTop: "2rem" }}>
        Please report to the team with the reference of the application from
        where you clicked this link.
      </Typography>
      <Typography variant="h5" sx={{ paddingTop: "2rem" }}>
        Click{" "}
        <a
          href={window.self.location.href}
          title="Web Application"
          target="blank"
          rel="noopener noreferrer"
        >
          here
        </a>{" "}
        to access The OneID Tech App safely.
      </Typography>
    </Box>
  )
}
