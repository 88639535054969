const env = process.env
const isProd = env.NODE_ENV === "production"

export const HOME_URL = "/"
export const BUSINESS_HOME_URL = "/business"
export const ABOUT_URL = "/about"
export const PRICING_URL = "/pricing"
export const ONE_CLICK_URL = "/one-click"
export const CONTACT_US_URL = "/support"
export const FAQ_URL = "/faq"
export const PRIVACY_POLICY_URL = "/privacy-policy"
export const TERMS_CONDITION_URL = "/terms-and-condition"
export const BLOG_URL = "/blog"

export const DEVELOPER_CONSOLE_URL = "https://console.oneidtech.com" // make it a dynamic link
export const DEVELOPER_DOCUMENTATION_URL = "https://developer.oneidtech.com/"
export const AUTH_BASE_URL = "https://auth.oneidtech.com"
export const FRONTDESK_URL = "https://frontdesk.oneidtech.com"

export const PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=com.oneidtech.oneid"
export const APP_STORE_URL = "https://apps.apple.com/app/oneid/id6443793097"

// Social Media links
export const ONEID_INSTAGRAM_URL =
  "https://instagram.com/oneidtech?igshid=YmMyMTA2M2Y="
export const ONEID_TWITTER_URL =
  "https://twitter.com/oneidtech?s=11&t=VxPDybCCqNZme0sjDCqZyQ"
export const ONEID_LINKEDIN_URL = "https://www.linkedin.com/company/oneidtech/"
