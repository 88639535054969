import * as React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { FaqAccordionBox } from "./FaqAccordion.style";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface IFaqAccordion {
  question: string;
  answer: string;
  index: number;
}

export const FaqAccordion: React.FC<IFaqAccordion> = ({
  question,
  answer,
  index,
}) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <FaqAccordionBox
      expanded={expanded === `panel${index}`}
      onChange={handleChange(`panel${index}`)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${index}bh-content`}
        id={`panel${index}bh-header`}
      >
        <h3 className="question">{question}</h3>
      </AccordionSummary>
      <AccordionDetails>
        <p className="answer">{answer}</p>
      </AccordionDetails>
    </FaqAccordionBox>
  );
};
