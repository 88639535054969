import React from "react"
import { Route, BrowserRouter as Router, Routes } from "react-router-dom"
import { Base } from "../components/layout"
import {
  ABOUT_URL,
  BUSINESS_HOME_URL,
  CONTACT_US_URL,
  FAQ_URL,
  HOME_URL,
  ONE_CLICK_URL,
  PRIVACY_POLICY_URL,
  ScrollToTop,
  TERMS_CONDITION_URL,
} from "../helpers"
import About from "../pages/About/About"
import BusinessHome from "../pages/Business-Home/BusinessHome"
import { PrivacyPolicy, TermAndCondition } from "../pages/Compliance"
import ContactUs from "../pages/Contact-Us/ContactUs"
import Faq from "../pages/Faq/Faq"
import Home from "../pages/Home/Home"
import OneClick from "../pages/OneClick/OneClick"

export const MainRoutes = () => {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path={HOME_URL} element={<Base />}>
            <Route index element={<Home />} />
            <Route path={ONE_CLICK_URL} element={<OneClick />} />
            <Route path={BUSINESS_HOME_URL} element={<BusinessHome />} />
            <Route path={ABOUT_URL} element={<About />} />
            <Route path={CONTACT_US_URL} element={<ContactUs />} />
            <Route path={"/contact-us"} element={<ContactUs />} />
            <Route path={FAQ_URL} element={<Faq />} />
            <Route path={TERMS_CONDITION_URL} element={<TermAndCondition />} />
            <Route path={PRIVACY_POLICY_URL} element={<PrivacyPolicy />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </Router>
  )
}
