import * as React from "react";
import { ContactBox } from "./ContactUs.style";
import ContactUsImg from "../../assets/images/contact_img.png";

const ContactUs = () => {
  return (
    <ContactBox>
      <section className="container contactSection">
        <div className="row">
          <div className="col-lg-6">
            <div className="headerText">
              <h3 className="title">Get in touch with us</h3>
              <p className="detail">
                Do you have any questions or suggestions on how we can make our
                service better for you? We are just one click away We'd love to
                hear from you
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <img src={ContactUsImg} alt="Get in touch with us in OneID" className="img-fluid" />
          </div>
        </div>
      </section>
    </ContactBox>
  );
};

export default ContactUs;
