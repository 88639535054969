import { styled } from "@mui/material"
import OneIDLogoBg from "../../assets/images/bg/oneid_logo_right_bottom.png"
import OneIDLogoBgCenter from "../../assets/images/bg/oneid_logo_bg.png"

export const HomeBox = styled("div")(({ theme }) => ({
  "& .curvedSection": {
    marginTop: "4.5rem",
    marginBottom: "3.5rem",
    borderRadius: "24px",
    padding: "3.5rem 1.5rem 2rem 5rem",
    backgroundImage: `url(${OneIDLogoBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom right",
    backgroundSize: "50%",

    "& .imageHolder": {
      "& img": {
        width: "100%",
      },
    },

    "@media (max-width:991.9px)": {
      padding: "unset",
      borderRadius: "unset",
      paddingTop: "3.5rem",
      paddingBottom: "3.5rem",
      backgroundSize: "contain",
    },
  },

  "& .textHolder": {
    // margin: "0 0 3rem 3rem",

    "& .subTitle": {
      fontSize: "1.5rem",
      fontWeight: 600,
      textTransform: "uppercase",
    },

    "& .title": {
      fontWeight: 800,
      fontSize: "3.5rem",
      width: "75%",
    },

    "& .detail": {
      fontSize: "1.125rem",
      fontWeight: 400,
      color: "#FFFFFF",
      margin: "1rem 4 rem 2rem 0",
    },

    "@media (max-width:1399.9px)": {
      "& .title": {
        fontSize: "3rem ",
        width: "90%",
        //   marginRight: "2.5rem",
      },

      "& .detail": {
        fontSize: "1rem",
        margin: "1rem 1rem 1.5rem 0",
      },
    },

    "@media (max-width:991.9px)": {
      margin: "0 0 3rem 0",

      "& .subTitle": {
        fontSize: "1.125rem !important",
      },

      "& .title": {
        fontSize: "2.5rem !important",
        width: "95%",
        marginRight: "2.5rem",
      },

      "& .detail": {
        fontSize: "1rem",
        margin: "1rem 1.2rem 1.7rem 0",
      },
    },
  },

  "& .personalSection": {
    backgroundColor: "#001935",

    "& .textHolder": {
      "& .subTitle": {
        color: "#F5C84A",
      },

      "& .title": {
        color: "#FFFFFF",
      },

      "& .detail": {
        color: "#FFFFFF",
      },
    },
  },

  "& .privacySection": {
    backgroundColor: "#E5F1FF",

    "& .imageHolder": {
      display: "flex",
      justifyContent: "center",
      width: "100%",

      "& img": {
        width: "70%",
      },
    },

    "& .textHolder": {
      "& .subTitle": {
        color: "#0B61DD",
      },

      "& .title": {
        color: theme.palette.secondary.main,
      },

      "& .detail": {
        color: "#272727",
      },
    },

    "@media (max-width:767.9px)": {
      backgroundPosition: "top center",
    },
  },

  "& .useCaseSection": {
    "& .textHolder": {
      marginBottom: "2.78rem",

      "& .title": {
        color: theme.palette.secondary.main,
        margin: "0",
        width: "100%",
      },

      "@media (max-width:767.9px)": {
        margin: "0 0 1.25rem 0",
      },
    },
  },

  "& .downloadSection": {
    marginTop: "20rem",
    marginBottom: "3.5rem",
    borderRadius: "24px",
    padding: "3.5rem 1.5rem 2rem 5rem",
    backgroundImage:
      "linear-gradient(85.03deg, #001935 26.97%, #00448F 99.49%)",

    "& .textHolder": {
      "& .subTitle": {
        color: "#4BE88A",
      },

      "& .title": {
        color: "#FFFFFF",
        fontSize: "2.375rem",
        marginRight: "0",
        width: "100%",
      },

      "& .detail": {
        color: "#FFFFFF",
      },

      "@media (max-width:1399.9px)": {
        "& .subTitle": {
          color: "1.2rem",
        },

        "& .title": {
          fontSize: "2rem",
        },

        "& .detail": {
          margin: ".75rem 1rem 1rem 0",
        },
      },
    },

    "& .imageHolder": {
      "& img": {
        width: "80%",
        margin: "-45% 0 0 6%",
      },
    },

    "@media (max-width:991.9px)": {
      padding: "3.5rem 0",
      marginTop: "3.5rem",
      marginBottom: "0",
      borderRadius: "0",
      backgroundImage:
        "linear-gradient(85.03deg, #001935 26.97%, #00448F 99.49%)",

      "& .imageHolder": {
        display: "flex",
        justifyContent: "center",

        "& img": {
          width: "80%",
          margin: "3.5rem auto",
        },
      },
    },
  },
}))

export const LogoComponentWrapper = styled("div")(({ theme }) => ({
  backgroundColor: "white",
  backgroundImage: `url(${OneIDLogoBgCenter})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top center",
  backgroundSize: "80%",
  padding: "2rem",

  [theme.breakpoints.down("md")]: {
    padding: "2rem 1rem",
  },
}))
