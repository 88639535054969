import * as React from "react";
import "./fingerprint.css";
import OneIDFingerPrintImg from "../../../../assets/images/finger-print/finger_print.gif";
import TopIconImg from "../../../../assets/images/finger-print/top-icon.svg";
import TopLeftIconImg from "../../../../assets/images/finger-print/top-left-icon.svg";
import BottomLeftIconImg from "../../../../assets/images/finger-print/bottom-left-con.svg";
import TopRightIconImg from "../../../../assets/images/finger-print/top-right-icon.svg";
import BottomRightIconImg from "../../../../assets/images/finger-print/bottom-right-icon.svg";
import BottomOneIconImg from "../../../../assets/images/finger-print/bottom-icon-1.svg";
import BottomTwoIconImg from "../../../../assets/images/finger-print/bottom-icon-2.svg";

export const FingerPrintAnimation = () => {
  return (
    <div className={"finger_print_container"}>
      <div className="icon-container">
        <img src={TopIconImg} alt="" className="top-icon" loading="lazy" />
        <img
          src={TopLeftIconImg}
          alt=""
          className="left-middle-icon"
          loading="lazy"
        />
        <img
          src={BottomLeftIconImg}
          alt=""
          className="left-bottom-icon"
          loading="lazy"
        />
        <img
          src={TopRightIconImg}
          alt=""
          className="right-middle-icon"
          loading="lazy"
        />
        <img
          src={BottomRightIconImg}
          alt=""
          className="right-bottom-icon"
          loading="lazy"
        />
        <img
          src={BottomOneIconImg}
          alt=""
          className="bottom-icon-1"
          loading="lazy"
        />
        <img
          src={BottomTwoIconImg}
          alt=""
          className="bottom-icon-2"
          loading="lazy"
        />
      </div>

      <img
        loading="lazy"
        src={OneIDFingerPrintImg}
        alt="OneID business globe"
        className={"finger_print img-fluid rounded-circle"}
      />
    </div>
  );
};
