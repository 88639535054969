import * as React from "react";
import { PrimaryButtonBox, OutlineButtonBox } from "./Button.style";
import SendIcon from "@mui/icons-material/Send";
import { Link } from "react-router-dom";

interface ButtonProps {
  text: string;
  loading?: boolean;
  click?(): any;
  externalLinkTo?: string;
  linkTo?: string;
}

export const PrimaryButton: React.FC<ButtonProps> = ({
  text,
  loading = false,
  click,
  externalLinkTo,
}) => {
  return (
    <PrimaryButtonBox className="" href={externalLinkTo} onClick={click}>
      {text}
    </PrimaryButtonBox>
  );
};

export const PrimaryOutlinedButton: React.FC<ButtonProps> = ({
  text,
  loading = false,
  click,
  externalLinkTo,
}) => {
  return (
    <OutlineButtonBox className="" href={externalLinkTo} onClick={click}>
      {text}
    </OutlineButtonBox>
  );
};
