import * as React from "react"
import IDM from "../../../../assets/images/idm.png"
import QMS from "../../../../assets/images/qms.png"
import { MPOWrap } from "./more-product-offerings.styles"
import { PrimaryButton } from "../../../../components/shared"
import { useNavigate } from "react-router-dom"
const Fade = require("react-reveal/Fade")

export const MoreProductOffering = () => {
  const navigate = useNavigate()

  return (
    <MPOWrap className="container">
      <div className="row mpo-data">
        <div className={`col-lg-6 imageSection order-2 order-lg-2`}>
          <div className="imageHolder">
            <img src={IDM} alt="id management" loading="lazy" />
          </div>
        </div>
        <div className={`col-lg-6 textSection order-1 order-lg-1`}>
          <div className="textHolder">
            <Fade bottom>
              <h6 className="title">ID Management</h6>
              <p className="detail">
                This is a customer focused mobile application that digitalizes
                customer ID Documents for secure sharing with financial related
                services.
              </p>
              <PrimaryButton
                text={"Learn More"}
                click={() => navigate("/contact-us")}
              />
            </Fade>
          </div>
        </div>
      </div>

      <div className="row mpo-data">
        <div className={`col-lg-6 imageSection order-1 order-lg-1`}>
          <div className="imageHolder">
            <img src={QMS} alt="queue management system" loading="lazy" />
          </div>
        </div>
        <div className={`col-lg-6 textSection order-2 order-lg-2`}>
          <div className="textHolder">
            <Fade bottom>
              <h6 className="title">Queue Management System</h6>
              <p className="detail">
                FrontDesk is a queue management system (QMS) that creates better
                experiences for visitors, guests and corporate event management.
              </p>
              <PrimaryButton
                text={"Learn More"}
                click={() => navigate("/contact-us")}
              />
            </Fade>
          </div>
        </div>
      </div>
    </MPOWrap>
  )
}
