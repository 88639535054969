import { styled } from "@mui/material";

export const ContactBox = styled("div")(({ theme }) => ({
  "& .contactSection": {
    paddingTop: "3rem",
    paddingBottom: "5rem",

    "& .headerText": {
      width: "80%",
      margin: "1rem auto 5.5rem 0",

      "& .title": {
        fontSize: "5.5rem",
        fontWeight: 800,
        marginBottom: "1.25rem",
        color: theme.palette.secondary.main,
      },

      "& .detail": {
        fontSize: "1.125rem",
        fontWeight: 400,
        lineHeight: "1.75",
      },

      "@media (max-width:991.9px)": {
        width: "100%",
        margin: "0 0 3rem 0",

        "& .title": {
          fontSize: "4rem",
        },

        "& .detail": {
          fontSize: "1rem",
        },
      },
    },
  },
}));
