import * as React from "react"
import TwoIPhoneImg from "../../assets/images/2_iphone.png"
import AppStoreImg from "../../assets/images/App_store.svg"
import GooglePlayImg from "../../assets/images/Google_play.svg"
import FinancialServiceImg from "../../assets/images/financial_service.svg"
import HealthServiceImg from "../../assets/images/health_service.svg"
import iPhoneDownloadForUser from "../../assets/images/iPhone_download_users.png"
import iPhoneWithBusiness from "../../assets/images/iphone_12_pro_max_business.png"
import iPhoneWithText from "../../assets/images/iphone_12_pro_max_graphite.png"
import iPhoneWithPrivacy from "../../assets/images/iphone_12_pro_max_privacy.png"
import PublicSectorServiceImg from "../../assets/images/public_sector_service.svg"
import TelecommounicationServiceImg from "../../assets/images/telecommunication_service.svg"
import { PrimaryButton } from "../../components"
import { AUTH_BASE_URL, BUSINESS_HOME_URL } from "../../helpers"
import { APP_STORE_URL, PLAY_STORE_URL } from "../../helpers/route-links"
import { OneClickBox } from "./one-click.styles"
import {
  HeroSection,
  ICaseCard,
  PhoneTextSection,
  UseCaseCard,
} from "./components"
// Using require() because there's no TypeScrpt support
const Fade = require("react-reveal/Fade")

const getWindowWidth = () => {
  const { innerWidth: width } = window
  return width
}

const OneClick = () => {
  const [windowWidth, setWindowWidth] = React.useState(getWindowWidth())

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(getWindowWidth())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const useCases: ICaseCard[] = [
    {
      image: FinancialServiceImg,
      title: "Financial Services",
      detail:
        "Access to financial services with just one click. Imagine not having to fill out a long form to open a bank account.",
    },
    {
      image: TelecommounicationServiceImg,
      title: "Telecommunication",
      detail:
        "Onboard customers and validate identities for sim swaps, products activation and much more.",
    },
    {
      image: HealthServiceImg,
      title: "Healthcare",
      detail:
        "Enable patient’s medical records and medications effortlessly accessible at all times.",
    },
    {
      image: PublicSectorServiceImg,
      title: "Public Sector",
      detail:
        "Keep accurate records of citizens. Set up e-voting platforms and reduce infrastructure costs by 90%.",
    },
  ]

  return (
    <OneClickBox>
      <HeroSection />
      <PhoneTextSection
        phoneImage={iPhoneWithText}
        title={"Your Personal Identity Manager"}
        subTitle={"OneID"}
        detail={
          "Safeguard your personal details while accessing multiple platforms in just one click. Choose the information you share and manage 3rd party access anytime. Start now with just one click."
        }
        buttonText={"Get Started"}
        buttonLink={`${AUTH_BASE_URL}/sign-up?d=app&r=/`}
        imageOrder="2"
        textOrder="1"
        imageOrderLg="1"
        textOrderLg="2"
      />

      <section className="container useCaseSection">
        <div className="textHolder">
          <h3 className="title">Use Cases</h3>
        </div>

        <div className="row">
          {useCases.map((useCase: ICaseCard, index: number) => (
            <div
              className="col-lg-3 col-md-6"
              key={`${index}-${useCase.title}`}
            >
              <Fade>
                <UseCaseCard
                  title={useCase.title}
                  detail={useCase.detail}
                  image={useCase.image}
                />
              </Fade>
            </div>
          ))}
        </div>
      </section>

      <section
        className={`${
          windowWidth >= 992 ? "container" : ""
        } curvedSection personalSection`}
      >
        <div className={`${windowWidth >= 992 ? "" : "container"}`}>
          <div className="row">
            <div className="col-lg-6 d-flex align-items-center">
              <div className="textHolder">
                <Fade bottom>
                  <h6 className="subTitle">Individual</h6>
                  <h3 className="title">Personal</h3>
                  <p className="detail">
                    Control how your personal information is shared and review
                    3rd party access anytime.{" "}
                  </p>

                  <PrimaryButton
                    text="Get Started"
                    click={() => {
                      window.open(
                        `${AUTH_BASE_URL}/sign-up?d=app&r=/`,
                        "_blank"
                      )
                    }}
                  />
                </Fade>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="imageHolder">
                <img
                  loading="lazy"
                  src={TwoIPhoneImg}
                  alt="Control how your personal information is shared and review 3rd
                party access anytime."
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <PhoneTextSection
        phoneImage={iPhoneWithBusiness}
        title={"Business"}
        subTitle={"Corporate"}
        detail={
          "Make it easier for customers to access your services in one click. Optimize your onboarding experience to boost conversion rates and build trust."
        }
        buttonText={"Learn more"}
        isExternalLink={false}
        buttonLink={BUSINESS_HOME_URL}
      />

      <section
        className={`${
          windowWidth >= 992 ? "container" : ""
        } curvedSection privacySection`}
      >
        <div className={`${windowWidth >= 992 ? "" : "container"}`}>
          <div className="row">
            <div className="col-lg-6 d-flex align-items-center order-2 order-lg-1">
              <div className="textHolder">
                <Fade bottom>
                  <h6 className="subTitle">Data Encryption</h6>
                  <h3 className="title">Take control of your privacy</h3>
                  <p className="detail">
                    Determine how you share your information with just a toggle.
                  </p>
                  <PrimaryButton
                    text="Get Started"
                    click={() => {
                      window.open(
                        `${AUTH_BASE_URL}/sign-up?d=app&r=/`,
                        "_blank"
                      )
                    }}
                  />
                </Fade>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="imageHolder">
                <img
                  loading="lazy"
                  src={iPhoneWithPrivacy}
                  alt="CDetermine how you share your information with just a toggle."
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className={`${windowWidth >= 992 ? "container" : ""} downloadSection`}
      >
        <div className={`${windowWidth >= 992 ? "" : "container"}`}>
          <div className="row">
            <div className="col-lg-6 d-flex align-items-center">
              <div className="textHolder">
                <Fade bottom>
                  <h6 className="subTitle">Download OneID</h6>
                  <h3 className="title">One means of identification</h3>
                  <p className="detail">
                    All of your information is at your fingertips with just one
                    click. Start now
                  </p>

                  <div className="d-flex align-items-center gap-3 mt-4">
                    <img
                      loading="lazy"
                      src={GooglePlayImg}
                      onClick={() => {
                        window.open(PLAY_STORE_URL, "_blank")
                      }}
                      alt="download OneID on playstore"
                    />
                    <img
                      loading="lazy"
                      src={AppStoreImg}
                      onClick={() => {
                        window.open(APP_STORE_URL, "_blank")
                      }}
                      alt="download OneID on App store"
                    />
                  </div>
                </Fade>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="imageHolder">
                <img
                  loading="lazy"
                  src={iPhoneDownloadForUser}
                  alt="All of your information is at your fingertips with just one
                  click. Start now"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </OneClickBox>
  )
}

export default OneClick
