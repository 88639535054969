import LeksImg from "../assets/images/team/CEO-new-img.png"
import SparksImg from "../assets/images/team/sparks_team_img.png"
import TosinImg from "../assets/images/team/PM_Tosin.png"
import MsVickImg from "../assets/images/team/UII:UX_MsVick.png"
import TstickImg from "../assets/images/team/FD_Tsticks.png"
import GozieImg from "../assets/images/team/BD_Gozie.png"
import JoshuaImg from "../assets/images/team/joshua_team_img.png"

export interface ITeam {
  name: string
  title: string
  photo: string
  order?: number | undefined
}

export const TeamLists: ITeam[] = [
  {
    name: "Olalekan Salami",
    title: "Co-Founder/ CEO",
    photo: LeksImg,
    order: 1,
  },
  {
    name: "Favour George",
    title: "Co-Founder/ CTO",
    photo: SparksImg,
    order: 2,
  },
  {
    name: "Oluwatosin Olabode",
    title: "Product Manager",
    photo: TosinImg,
    order: 3,
  },
  {
    name: "Victoria Alli-Johnson",
    title: "Product Designer",
    photo: MsVickImg,
    order: 7,
  },
  {
    name: "Oluwatobi Taiwo",
    title: "Lead (Frontend) Developer",
    photo: TstickImg,
    order: 5,
  },
  {
    name: "Chigozie Ezechukwu",
    title: "Lead (Backend) Developer",
    photo: GozieImg,
    order: 6,
  },
  {
    name: "Joshua Uzor",
    title: "Mobile App Developer",
    photo: JoshuaImg,
    order: 8,
  },
]
