import * as React from "react"
import { HomeBox, LogoComponentWrapper } from "./Home.style"
import {
  AccurateAddress,
  BackedBy,
  ExperienceTheFuture,
  Features,
  HeroSection,
  MoreProductOffering,
  SetupWorkflow,
} from "./components"

// Using require() because there's no TypeScrpt support
const Fade = require("react-reveal/Fade")

const getWindowWidth = () => {
  const { innerWidth: width } = window
  return width
}

const Home = () => {
  const [windowWidth, setWindowWidth] = React.useState(getWindowWidth())

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(getWindowWidth())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <HomeBox>
      <HeroSection />
      <AccurateAddress />
      <Features />

      <LogoComponentWrapper>
        <ExperienceTheFuture />
        <MoreProductOffering />
        <SetupWorkflow />
        <BackedBy />
      </LogoComponentWrapper>
    </HomeBox>
  )
}

export default Home
