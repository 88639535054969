import React from "react";
import { MainRoutes } from "./routes/main-routes";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "./components/themes";

function App() {
  return (
    <ThemeProvider>
      <CssBaseline />
      <MainRoutes />
    </ThemeProvider>
  );
}

export default App;
