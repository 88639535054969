import React from "react"
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles"
import { theme } from "./themes"
import { DateTimeProvider } from "../shared/DateTimeProvider"

interface Props {
  children: React.ReactNode
}
export const ThemeProvider: React.FC<Props> = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <DateTimeProvider>{children}</DateTimeProvider>
  </MuiThemeProvider>
)
