import * as React from "react";
import { AboutBox } from "./About.style";
import VisionImage from "../../assets/images/vision_img.png";
import PrivacyImg from "../../assets/images/privacy_cv.png";
import HonestyImg from "../../assets/images/honesty_cv.png";
import TrustImg from "../../assets/images/trust_cv.png";
import { TeamCard } from "./components/TeamCard";
import { TeamLists, ITeam } from "../../mock-data/teams";

// Using require() because there's no TypeScrpt support
const Fade = require("react-reveal/Fade");

interface ICoreValue {
  name: string;
  detail: string;
  image: string;
}

const About: React.FC = () => {
  const coreValues: ICoreValue[] = [
    {
      name: "Privacy",
      detail:
        "We know that your privacy is very important to you, hence we empower you to express yourself as selectively as you would prefer.",
      image: PrivacyImg,
    },
    {
      name: "Honesty",
      detail: "We are promoting a genuine environment driven by true data.",
      image: HonestyImg,
    },
    {
      name: "Trust",
      detail:
        "We want to facilitate the internet to be a more trustworthy environment for people to meet and transact without fear of being scammed.",
      image: TrustImg,
    },
  ];
  return (
    <AboutBox>
      <header className="headerSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="headerText">
                <Fade bottom>
                  <h3 className="title">About Us</h3>
                </Fade>
                <Fade bottom>
                  <p className="detail">
                    OneID Global Technologies is the first digital identity
                    management company in Nigeria. We are a registered identity
                    verification and authentication startup, based in Nigeria,
                    incorporated in the USA.
                    {/* <br />
                  <br />
                  OneID was founded in 2019 to provide solutions to digital
                  identity issues in Africa. We have been working on a mobile
                  application alongside the rebranding of our website where we
                  would offer a wider range of services than we already have. */}
                    <br />
                    <br />
                    We prioritize privacy, security and ease. Our mission drives
                    us to do everything possible to ensure that all our users
                    have control over their privacy and feel safe and secure
                    online. We have done that by using a secure encryption
                    system to protect all data that is being shared with OneID.
                  </p>
                </Fade>
              </div>
            </div>
            <div className="col-lg-6"></div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className="textHolder">
                <Fade bottom>
                  <h3 className="title">Mission</h3>
                </Fade>
                <Fade bottom>
                  <p className="detail text-justify">
                    We look to facilitate a trustworthy environment where users
                    are able to securely share data with businesses and services
                    in one click.
                  </p>
                </Fade>
              </div>
            </div>
            <div className="col-lg-6"></div>
          </div>
        </div>
      </header>

      <section className="visionSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img
                src={VisionImage}
                alt="OneID vision"
                className="img-fluid"
                loading="lazy"
              />
            </div>
            <div className="col-lg-6 d-flex align-items-center">
              <div className="textHolder">
                <Fade bottom>
                  <h3 className="title">Vision</h3>
                </Fade>
                <Fade bottom>
                  <p className="detail text-justify">
                    To facilitate a more digitally inclusive &amp; effective
                    economy for Africa and guarantee equitable and secure access
                    to opportunities.
                  </p>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="coreValueSection">
        <div className="container">
          <div className="textHolder">
            <h3 className="title">Core Values</h3>
          </div>
          <div className="row">
            {coreValues.map((value: ICoreValue, index: number) => {
              return (
                <div className="col-lg-4 cool-md-6" key={index}>
                  <Fade>
                    <div className="card coreCard">
                      <img
                        src={value.image}
                        alt={value.detail}
                        className="img-fluid"
                        width={"80"}
                        height={"80"}
                      />
                      <h5 className="title"> {value.name} </h5>
                      <p className="detail"> {value.detail} </p>
                    </div>
                  </Fade>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="teamSection">
        <div className="container">
          <div className="textHolder">
            <h3 className="title">Our Amazing Team</h3>
            <p className="detail">
              We are a diverse team of multitalented and goal-oriented people
              committed to creating a safe and stress-free environment for
              everyone.
            </p>
          </div>

          <div className="row d-fleex justify-content-center">
            {TeamLists.sort((a: ITeam, b: ITeam) => a.order! - b.order!).map(
              (team: ITeam, index: number) => {
                return (
                  <div className="col-lg-4 col-6" key={`${team.name}-${index}`}>
                    <TeamCard
                      name={team.name}
                      title={team.title}
                      photo={team.photo}
                    />
                  </div>
                );
              }
            )}
          </div>
        </div>
      </section>
    </AboutBox>
  );
};

export default About;
