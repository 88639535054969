import { styled } from "@mui/material";

export const BusinessHomeBox = styled("div")(({ theme }) => ({
  "& .headerSection": {
    paddingTop: "4rem",
    paddingBottom: "5rem",
    transition: "0.3s all ease",

    "& .heroText": {
      width: "75%",

      "& .title": {
        fontWeight: 700,
        fontSize: "4rem",
        color: theme.palette.secondary.main,
      },

      "& .goldLine": {
        position: "relative",
        display: "inline-block",

        "&::before": {
          content: '" "',
          height: "8px",
          width: "98%",
          background: "#F2BA1D",
          position: "absolute",
          bottom: "13px",
          left: "5px",
          zIndex: "-1",

          "@media (max-width:991.9px)": {
            bottom: "10px",
          },
        },
      },

      "& .details": {
        fontSize: "1.25rem",
        color: "#272727",
        margin: "1rem 3rem 1.7rem 0",
      },

      "& .MuiButton-root": {
        height: "64px ",
      },

      "@media (max-width:1199.9px)": {
        width: "85%",

        "&  .title": {
          fontSize: "3.25rem",
        },

        "& .details": {
          fontSize: "1.125rem",
        },
      },

      "@media (max-width:991.9px)": {
        width: "100%",
        marginRight: "0",

        "&  .title": {
          fontSize: "3rem !important",
        },

        "& .details": {
          fontSize: "1rem",
          margin: "1rem 1.5rem 1.7rem 0",
        },
      },
    },
  },

  //  Reuseable text size
  "& .textHolder": {
    width: "80%",

    "& .subTitle": {
      fontSize: "1.5rem",
      fontWeight: 600,
      color: "#00499B",
    },

    "& .title": {
      fontSize: "3rem",
      fontWeight: 800,
      color: "#001935",
    },

    "& .detail": {
      fontSize: "1.125rem",
      margin: "1rem 0 2.5rem",
    },

    "@media (max-width:1199.9px)": {
      width: "90%",

      "& .subTitle": {
        fontSize: "1.25rem",
      },

      "&  .title": {
        fontSize: "2.25rem",
      },

      "& .details": {
        fontSize: "1.125rem",
      },
    },

    "@media (max-width:991.9px)": {
      width: "95%",
      margin: "3rem 0 !important",

      "& .subTitle": {
        fontSize: "1.25rem",
      },

      "& .title": {
        fontSize: "2.5rem !important",
      },

      "& .detail": {
        fontSize: "1rem",
      },
    },
  },

  "& .developerSection": {
    paddingTop: "3rem",
    paddingBottom: "3rem",
    background: "#0C1021",

    "& .textHolder": {
      marginLeft: "4rem",

      "& .subTitle": {
        color: "#F2BA1D",
      },

      "& .title": {
        color: "#FFFFFF",
        margin: "1rem 0 2.5rem",
        width: "90%",
      },

      "@media (max-width:991.9px)": {
        margin: "0 0 3rem 0",

        "& .title": {
          width: "97%",
        },
      },
    },
  },

  "& .frontdeskSection": {
    paddingTop: "3rem",
    paddingBottom: "3rem",

    "& .textHolder": {
      margin: "0 3rem 0 auto",

      "& .title": {
        width: "90%",
      },

      "@media (max-width:991.9px)": {
        "& .title": {
          width: "97%",
        },
      },
    },
  },

  "& .financialSection": {
    paddingTop: "3rem",
    paddingBottom: "3rem",

    // Accessing a direct bootstrap class here -Be mindful :)
    "& .container": {
      background: "#E2EDFE",
      borderRadius: "24px",
      paddingTop: "8px",
      paddingBottom: "8px",
    },

    "& .textHolder": {
      margin: "0 auto 0 3rem",

      "& .title": {
        width: "90%",
      },

      "@media (max-width:991.9px)": {
        "& .title": {
          width: "97%",
        },
      },
    },
  },

  "& .travelSection": {
    paddingTop: "3rem",
    paddingBottom: "3rem",

    "& .textHolder": {
      margin: "0 3rem 0 auto",

      "& .title": {
        width: "90%",
      },

      "@media (max-width:991.9px)": {
        "& .title": {
          width: "97%",
        },
      },
    },
  },

  "& .workSmartSection": {
    margin: "3rem auto",
    background: "#001935",
    borderRadius: "24px",
    display: "flex",
    justifyContent: "center",

    "& .textHolder": {
      padding: "4.5rem 0",
      textAlign: "center",

      "& .title": {
        fontSize: "3rem",
        fontWeight: 800,
        color: "#ffffff",
        marginBottom: "2.95rem",
      },
    },

    "@media (max-width:991.9px)": {
      borderRadius: "0",

      "& .textHolder": {
        padding: "3rem 0",

        "& .title": {
          fontSize: "1.875rem",
          marginBottom: "2rem",
        },
      },
    },
  },
}));
