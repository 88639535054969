import { Grid, Typography } from "@mui/material"
import { AccurateAddressBox } from "./accurate-address.styles"
import { PrimaryButton } from "../../../../components"
const Fade = require("react-reveal/Fade")
import React from "react"
import AddressVerificationImg from "../../../../assets/images/address-verification.png"
import { AUTH_BASE_URL } from "../../../../helpers"

export const AccurateAddress = () => {
  return (
    <AccurateAddressBox>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 d-flex align-items-center">
            <div>
              <Fade>
                <h3 className="title">
                  Accurate address verification with our robust API-powered
                  solution
                </h3>
                <Typography>
                  Our innovative system utilizes electricity meter number to
                  streamline the address verification process for banks and
                  Fintech companies. Say goodbye to manual data entry and
                  unreliable methods.
                </Typography>
              </Fade>

              <div className="d-flex align-items-left justify-content-start cta-wrap">
                <Fade bottom>
                  <PrimaryButton
                    text={"Get Started"}
                    click={() => {
                      window.open(
                        `${AUTH_BASE_URL}/sign-up?d=console&r=/`,
                        "_blank"
                      )
                    }}
                  />
                </Fade>
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-flex justify-content-center align-items-center image-wrap">
            <img
              loading="lazy"
              src={AddressVerificationImg}
              alt="Address verification"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </AccurateAddressBox>
  )
}
