import { useNavigate } from "react-router-dom"
import MeterNoLookupImg from "../../../../assets/images/meter-no-lookup.png"
import { PrimaryButton, PrimaryOutlinedButton } from "../../../../components"
import { HeaderBox } from "./HeroSection.style"
// Using require() because there's no TypeScrpt support
const Fade = require("react-reveal/Fade")
import React from "react"
import { AUTH_BASE_URL } from "../../../../helpers"

export const HeroSection = () => {
  const navigate = useNavigate()

  return (
    <HeaderBox className="container">
      <div className="row">
        <div className="col-lg-12 d-flex align-items-center">
          <div className="heroText">
            <Fade>
              <h3 className="title">
                Experience seamless address verification with electricity meter
                number
              </h3>
            </Fade>
            <Fade bottom>
              <p className="details">
                Our solution empowers your organization to verify addresses with
                speed, accuracy, and ease.
              </p>
            </Fade>

            <div className="d-flex align-items-center justify-content-center gap-4 appBtnContainer">
              <PrimaryButton
                text={"Get Started"}
                click={() =>
                  window.open(
                    `${AUTH_BASE_URL}/sign-up?d=console&r=/`,
                    "_blank"
                  )
                }
              />

              <PrimaryOutlinedButton
                text={"Book a Demo"}
                click={() => navigate("/contact-us")}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-12 d-flex justify-content-center align-items-center image-wrap">
          <img
            loading="lazy"
            src={MeterNoLookupImg}
            alt="Meter number lookup"
            className="img-fluid"
          />
        </div>
      </div>
    </HeaderBox>
  )
}
